import React from "react";
import { menuItems } from "../menuItems";
import MenuItem from "./MenuItem";
import styled from "styled-components";

function Navbar() {
  return (
    <Stylednav>
      {/*   <nav> */}
      <ul className="menus">
        {menuItems.map((menu, index) => {
          const depthLevel = 0;
          return <MenuItem items={menu} key={index} level={depthLevel} />;
        })}
      </ul>
      {/*   </nav> */}
    </Stylednav>
  );
}

export default Navbar;

const Stylednav = styled.nav`
  /* width: 70vw; */

  @media (max-width: 700px) {
    display: block; /* Ensure display is flex */
    height: 100vh;
    z-index: 999;
    /*   background: #0496ff; */
  }
`;
