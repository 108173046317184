import React, { useEffect, useState } from "react";
/* import { StreeFreeFadeData } from "./StreeFreeData"; */
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import slideimg1 from "../images/corprelopic.jpeg";
import slideimg2 from "../images/creditcard.jpg";
import slideimg3 from "../images/movetoghpic.jpg";
import styled from "styled-components";

function StreeFreeFade() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = StreeFreeFadeData.length;
  const autoScroll = true;
  const intervalTime = 7000;
  let slideInterval;

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(
    () => {
      if (autoScroll) {
        auto();
      }
      return () => clearInterval(slideInterval);
    } /* [currentSlide] */
  );

  return (
    <HeroSliderCon>
      <div className="slider">
        <FaRegArrowAltCircleLeft className="arrow prev" onClick={prevSlide} />
        <FaRegArrowAltCircleRight className="arrow next" onClick={nextSlide} />
        {StreeFreeFadeData.map((slide, index) => (
          <div
            className={index === currentSlide ? "slide current" : "slide"}
            key={index}
          >
            {index === currentSlide && (
              <>
                <img className="picplusdetalis" src={slide.image} alt="slide" />
                <div className="content">
                  <h3>{slide.heading}</h3>
                  <p>{slide.desc}</p>
                  <hr />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </HeroSliderCon>
  );
}

export default StreeFreeFade;

export const StreeFreeFadeData = [
  {
    image: slideimg1,
    heading: "We will deliver our on promise",
    desc: "Don’t Move, We’ll Handle Everything",
  },
  {
    image: slideimg2,
    heading: "Professional staff",
    desc: "We have the professional staff who are ready to serve you",
  },
  {
    image: slideimg3,
    heading: "Great warehouse",
    desc: "Great warehouse provider",
  },
];

const HeroSliderCon = styled.div`
  margin: auto;
  width: 40vw;
  height: 45vh;
  /*  background: rgba(0, 0, 0, 0.6); */
  margin-bottom: 50px;
  background-color: #003554;

  .picplusdetalis {
    width: 38vw;
    height: 50vh;
    border-radius: 0px 200px 200px 0px;
  }

  img {
    width: 40vw;
    height: 45vh;
  }

  .slider {
    width: 100%;
    height: 45vh;
    position: relative;
    overflow: hidden;
    margin-top: 0;
  }

  .slide {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 45%;
    animation: fadeinimg 5s cubic-bezier(0.25, 0.1, 0.25, 1) ease-in-out;
  }

  @keyframes fadeinimg {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .content {
    position: absolute;
    top: 10rem;
    left: 4.5rem;
    opacity: 0;
    width: 35%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.6);
    animation: fadeinimg 5s cubic-bezier(0.25, 0.1, 0.25, 1) forwards;
  }

  .content > * {
    color: #fff;
    margin-bottom: 0.2rem;
  }

  @keyframes slide-up {
    from {
      visibility: visible;
      top: 0rem;
    }
    100% {
      visibility: visible;
      top: 10rem;
    }
  }

  .current {
    opacity: 1;
    transform: translateX(0);
  }

  .current .content {
    opacity: 1;
  }

  .arrow {
    border: 2px solid #fff;
    border-radius: 50%;
    background: #333;
    color: #fff;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    top: 50%;
    z-index: 999;
  }

  .arrow:hover {
    background: hsl(229, 54%, 51%);
  }

  .next {
    right: 2rem;
  }

  .prev {
    left: 2rem;
  }

  hr {
    width: 55%;
  }

  @media screen and (max-width: 700px) {
    width: 100vw;
    height: 45vh;

    .slide img {
      width: 100vw;
      height: 35vh;
    }

    .content {
      top: 10rem;
      left: 1rem;
      width: 50vw;
    }

    .content > * {
      font-size: 16px;
    }
  }
`;
