import React, { useState } from "react";
import styled from "styled-components";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";

function Faq() {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleAnswer = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <FaqCon>
      <div className="imgandesc">
        <div className="image-container">
          <h2>Got Questions? We Have Answers</h2>
          <br />
          <p>
            Freight forwarders can save you all sorts of time, money and energy.
            It’s usually worth the extra expense to hire one of these outfits to
            take some of the hassle off your plate. You’ve got enough to worry
            about already. Choose NOBLE SHIPPING AGENCY. The changer in your
            export and import goods.
          </p>
        </div>
        <div className="desc-container">
          {QuestinsAnsData.map((feq_qes, index) => (
            <div className="QuesAnsCon" key={index}>
              <div className="QesAns">
                <div
                  className="ArrQuestions"
                  onClick={() => toggleAnswer(index)}
                >
                  {expandedIndex === index ? (
                    <IoMdArrowDropdown />
                  ) : (
                    <IoMdArrowDropright />
                  )}
                  <div className="Questions">
                    <p>{feq_qes.Questions}</p>
                  </div>
                </div>
                <br />
                {expandedIndex === index && (
                  <div className="Answers">
                    {Array.isArray(feq_qes.Answer) ? (
                      <ul>
                        {feq_qes.Answer.map((item, i) => (
                          <li key={i}>{item}</li>
                        ))}
                      </ul>
                    ) : (
                      <p>{feq_qes.Answer}</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </FaqCon>
  );
}

export default Faq;

export const QuestinsAnsData = [
  {
    Questions:
      "Why should I consider Noble Shipping Agency for my freight forwarding needs from Ghana?",
    Answer:
      "When it comes to freight forwarding, Noble Shipping Agency emerges as a top-notch choice. Operating with a Western team, we adhere to the International standards of service, security, and support. Our approach is grounded in efficiency and the smart use of local resources, which allows us to offer affordable pricing without sacrificing the quality of our service. Our commitment extends to both small and large volume imports, promising a seamless shipping experience from start to finish.",
  },
  {
    Questions:
      "What specialized freight forwarding services does NOBLE Shipping Agency offer?",
    Answer: [
      "SINO Shipping is a comprehensive freight forwarding service provider, offering a wide array of solutions tailored to meet the diverse needs of our clients. Our primary services include:",
      "Sea Freight: A cost-effective solution for large, less time-sensitive shipments.",
      "Air Freight: A quicker, albeit typically pricier, option perfect for smaller, time-critical shipments.",
      "Door to Door Service: Taking care of your shipment from the origin to the final destination.",
      "Project Cargo handling.",
      "Warehousing and Storage Services.",
      "Road Freight: Efficient service for domestic and cross-border shipments.",
    ],
  },
  {
    Questions:
      "How does the freight forwarding process work at NOBLE Shipping Agency?",
    Answer:
      "NOBLE Shipping aims to simplify the freight forwarding process for its clients. Here's our standard procedure: Once your goods are ready, either your supplier or we organize pickup and delivery to our shipping facility. We request your supplier to complete the necessary shipping documents and create a commercial invoice. Upon arrival of your goods at our warehouse, we verify their exact weight and dimensions and cross-check them with you. After confirming the details, you receive a shipping invoice from us for payment. We handle the export customs clearance for goods transported via air or sea freight. Following customs clearance, the goods are delivered to the designated address.",
  },
  {
    Questions: "Do I need to secure cargo insurance for my shipments?",
    Answer:
      "While NOBLE Shipping Agency does provide cargo insurance, it's not mandatory. However, in the rare event of cargo damage or loss, we highly recommend securing insurance, particularly for high-value goods. The insurance serves as a safety net, providing financial coverage and peace of mind in unforeseen circumstances.",
  },
  {
    Questions:
      "How do I determine the most appropriate shipping option for my needs?",
    Answer:
      "At NOBLE Shipping Agency, we believe in personalized service. Therefore, we suggest the most suitable shipping option based on your specific requirements. While requesting a quote, providing us with all shipment details, including your delivery deadline and whether cost or speed is your priority, will help us recommend the most effective solution for you.",
  },
  {
    Questions: "What Is Required To Calculate A Shipping Quote?",
    Answer: [
      "Firstly you should get a Quotation / Proforma Invoice from your Suppliers, in that document should be:",

      "Delivery Address",
      "Suppliers Address",
      "Suppliers Contacts",
      "Description of Goods",
      "Weight & Dimensions of Products",
    ],
  },
];

const FaqCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .QuesAnsCon .QesAns {
    gap: 20px;
  }

  .QesAns {
    border: 1px solid gainsboro;
    gap: 100px;
    padding: 10px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;
    text-align: justify;
  }

  .ArrQuestions {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .Answers ul {
    list-style-type: disc;
    padding-left: 20px;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-container {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
