import React from "react";
import styled from "styled-components";
import img1 from "../images/Customs-Clearance.png";
import bkgroundimg from "../images/cuspic.jpg";

function CustomsBrokage() {
  return (
    <CustomsBrokageCon>
      <div className="bkgpic_msg">
        <div className="message">Customs Brokage</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Custom Brokage
      <div className="imgandesc">
        <div className="desc-container">
          Domestic Freight Forwarding As a fully licensed Customs House Agent,
          we arrange the import and export clearance of goods and services for
          clients through customs and other government agencies. We look at our
          clients’ businesses as extensions of ours, so we ask pertinent
          questions and vet clients’ documents to ensure that goods are cleared
          compliantly. We are responsible for knowing all the rules that exist
          in the industry and ensuring that they are followed to streamline the
          process of clearance. We take the place of our clients in facing the
          stress of dealing with government officials so that our clients can
          focus more on managing their businesses. We carefully expedite action
          in all our activities to avoid unnecessary rent charges that accrue
          because of delays, thus shrinking the profit margins of our clients.
          To do this, we make it our objective to handle and deliver shipments
          to our clients within the shortest possible time. <br /> <br />
          <h3>International Freight Forwarding</h3>
          <br />
          We have established a global presence to coordinate international
          freight forwarding. This is made possible through our strategic
          partnerships with global forwarders like Schenker, Agility and
          Bertling. Our international freight forwarding activities include the
          following:
          <ul>
            <li>Providing cost-effective and efficient cargo shipping</li>
            solutions based on the customer’s requirement
            <li>Arranging storage for the cargo</li>
            <li>
              Organizing the distribution or “forwarding” of the shipment as per
              the instructions of the client
            </li>
            <li>
              Negotiating freight rates with the shipping/airline Booking
              shipment with the shipping/airline as per the requirement of the
              client
            </li>
            <li>
              Processing all relevant shipping documents such as certificates of
              origin, customs and port documentation, bills of lading and
              associated shipping/negotiating documentation
            </li>
            <li>
              Arranging transportation of the cargo from/to the customers’
              premises and port.
            </li>
          </ul>
        </div>

        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
      </div>
    </CustomsBrokageCon>
  );
}

export default CustomsBrokage;

const CustomsBrokageCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    color: white;
    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
