import React from "react";
import styled from "styled-components";
import img1 from "../images/truck1.avif";
import img2 from "../images/tk2.webp";
import img3 from "../images/heavy-cargo2.jpeg";
import noblepro from "../images/nobltruck.jpg";

import bkgroundimg from "../images/tk1.jpg";
function Transborder() {
  return (
    <TransborderCon>
      <div className="bkgpic_msg">
        <div className="message">
          Cross Border Transport Companies: Making The Right Choice
        </div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Transborder
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          Noble provides logistics services including LTL, Flatbed Heavy
          Freight, Intermodal, Refrigerated, and more. Avrio provides a
          perfectly sized fleet with highly trained drivers for your needs.
          Noble provides a perfectly sized fleet with highly trained drivers for
          your needs. Manage information, assets, and costs. Our superior
          customer service allows you to ensure the success of your clients and
          those depending on you. <br />
          <p>
            We pair your domestic and cross-border ground freight with the best
            transportation solutions to meet your needs, including:
            <br />
            <br />
            <ul>
              <li>Truckload shipping</li> <li>Dry-van trucking </li>
              <li>Flatbed trucks</li> <li>Refrigerated units</li> Less than
              truckload <li>Expedited ground service</li>
              <li>Service on weekends and holidays</li>
            </ul>
          </p>
        </div>
        <div className="desc-container">
          <h3>Partner with trusted transborder logistics experts</h3>
          <p>
            Whether you're looking for cross-border trucking help, air freight
            support, warehousing assistance, or something else entirely, you can
            trust Noble for cost-effective cross-border logistics solutions,
            tailored to your needs.
          </p>
        </div>
        <div className="image-container">
          <img className="image" src={img2} alt=" " />
        </div>
        <div className="image-container">
          <img className="image" src={img3} alt=" " />
        </div>
        <div className="desc-container">
          <h3> Empowering Industrial Progress </h3>
          <p>
            Industrial projects, refineries, and large-scale constructions
            demand precise transport engineering, meticulous management, and
            flawless execution. With our global presence of 50 offices across 30
            countries, comprehensive capabilities in all modes of transport, and
            a dedicated project team, Fracht is equipped to handle logistically
            challenging projects with ease. We uphold the highest standards of
            health, safety, and environmental certifications, ensuring that
            complex components are delivered with the quality and safety our
            clients expect.
          </p>
        </div>
      </div>
      <div className="noblepro">
        <div className="messagepro">
          <h1>Partner with trusted transborder logistics experts</h1>
          <p>
            Whether you're looking for cross-border trucking help, air freight
            support, warehousing assistance, or something else entirely, you can
            trust NOBLE for cost-effective cross-border logistics solutions,
            tailored to your needs.
          </p>
          <br />
          <button>
            <a href="/GetaQuoteCon">SHIP WITH NOBLE</a>
          </button>
        </div>
      </div>
      <br />
      <br />
    </TransborderCon>
  );
}

export default Transborder;

const TransborderCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .noblepro {
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.1),
        rgba(0, 0, 0, 0.1)
      ),
      url(${noblepro});

    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 65vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }
  .messagepro {
    /*     background: gainsboro; */
    border-radius: 0px 50px 50px 0px;
    position: relative;
    width: 50%;
    font-weight: bold;
    padding: 5px;
    text-align: left;
    color: white;
    margin-top: -200px;
  }

  .message {
    font-weight: bold;
    color: white;
    font-size: 40px;
  }

  button {
    position: relative;
    bottom: 0px;
    background: white;
    color: red;
    cursor: pointer;
    padding: 8px;
    border-radius: 5px;
  }

  button:hover {
    background: #023e7d;
  }

  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
    .noblepro {
      width: 100%;
      margin-left: -60px;
    }
    .messagepro {
      width: 90%;
      font-weight: bold;
      margin-top: -250px;
      color: white;
    }
  }
`;
