import React from "react";
import styled from "styled-components";
import img1 from "../images/getquote.jpg";
import { useForm } from "react-hook-form";
import { DevTool } from "@hookform/devtools";
import CtryofDeparture from "./CtryofDeparture";
import PortofDeparture from "./PortofDeparture";
import CtryofArrival from "./CtryofArrival";
import ShipmentDate from "./ShipmentDate";
import bkgroundimg from "../images/Request-Quote.jpg";
function GetaQuote() {
  const Form = useForm();
  const { register, control } = Form;
  /*   const { name, ref, onChange, onBlur } = register("purchase_incoterm"); */
  /*   renderCount++; */
  return (
    <GetaQuoteCon>
      <div className="bkgpic_msg">
        <div className="message">
          Request a Freight Shipping Quote for Your Cargo
        </div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Get Quote
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          <p>
            FILL OUT THE FORM BELOW TO GET YOUR INTERNATIONAL FREIGHT QUOTE!
            With NOBLE Shipping, getting a freight quote is easy! Select the
            type of goods you want to move and fill out the form below. Our
            shipping experts will provide you with our most competitive rates as
            soon as they have received your request.
          </p>
          <br />
          For quote requests, please provide as many shipment details as
          possible.
          <br />
          <br />
          <h2>GET A QUOTE </h2>
          <h4>WELCOME TO NOBLE SHIPPING AGENCY</h4>
          <br />
          <form>
            <div>
              <label className="labclass" htmlFor="purchase_incoterm">
                Purchase incoterm
              </label>
              <br />
              <select
                className="datafield"
                id="purchase_incoterm"
                {...register("purchase_incoterm")}
                /*         ref={ref}
                onChange={ref}
                onBlur={onBlur}  */
              >
                <option value="exw">EXW Ex Works</option>
                <option value="cip">CIP Carriage Paid, Insurance</option>
                <option value="dat">DAT Delivered at Terminal (new)</option>
                <option value="dap">DAP Delivered at Place (new)</option>
                <option value="ddp">DDP Delivered Duty Paid</option>
                <option value="cpt">CPT Carriage Paid To</option>
                <option value="fca">FCA Free Carrier</option>
                <option value="fas">FAS Free Alongside Ship</option>
              </select>
            </div>
            <br />
            <label className="labclass" htmlFor="pickaddress">
              Pick Address
            </label>
            <br />
            <input
              className="datafield"
              type="text"
              id="pickaddress"
              {...register("pickaddress")}
            />
            <br />
            <label className="labclass" htmlFor="ctryofdeparture">
              Country of departure*
            </label>
            <br />
            <CtryofDeparture />
            <br />
            <label className="labclass" htmlFor="portofdeparture">
              Port of Departure
            </label>
            <br />
            <PortofDeparture />
            <br />
            <label className="labclass" htmlFor="ctryofarrival">
              Country of arrival*
            </label>
            <br />
            <CtryofArrival />
            <label className="labclass" htmlFor="deliveryaddress">
              Delivery Address
            </label>
            <br />
            <input
              className="datafield"
              type="text"
              id="deliveryaddress"
              {...register("deliveryaddress")}
            />
            Date of the shipment
            <ShipmentDate />
            <h2>Your shipment</h2>
            <br />
            <label className="labclass" htmlFor="kindofgoods">
              Kind of goods*
            </label>
            <br />
            <input
              type="text"
              id="kindofgoods"
              {...register("kindofgoods")}
              className="datafield"
              style={{ width: "100%", height: "100px", resize: "vertical" }} // Style for better usability
              rows="7" // You can adjust the rows and cols to fit your needs
              cols="80"
            />
            <label className="labclass" htmlFor="totalweight">
              Total weight (in Kgs)*
            </label>
            <br />
            <input
              type="text"
              id="totalweight"
              {...register("totalweight")}
              className="datafield"
            />
            <br />
            <label className="labclass" htmlFor="volume">
              Volume (in m3/cbm)*
            </label>
            <br />
            <input
              type="text"
              id="volume"
              {...register("volume")}
              className="datafield"
            />
            <br />
            <div>
              <label className="labclass" htmlFor="currentpackaged">
              Current Packages
              </label>
              <br />
              <select
                className="datafield"
                id="currentpackaged"
                {...register("currentpackaged")}
                /*         ref={ref}
                onChange={ref}
                onBlur={onBlur}  */
              >
                <option value="npkaged">Not Packaged</option>
                <option value="wcrate">Wooden Crate</option>
                <option value="cart">Cartons</option>
                <option value="pall">Palltized</option>
                <option value="can">Infut/Can</option>
                <option value="bags">Bags</option>
                <option value="oth">Other</option>

              </select>
            </div>
<h2>About you</h2><br />
<label className="labclass" htmlFor="companyname">
Company Name (if any)
</label>
<br />
<input
  className="datafield"
  type="text"
  id="companyname"
  {...register("companyname")}
/>
<br />
<label className="labclass" htmlFor="fullname">
Full Name
</label>
<br />
<input
  className="datafield"
  type="text"
  id="fullname"
  {...register("fullname")}
/>
<br />
<label className="labclass" htmlFor="email">
E-mails*
</label>
<br />
<input
  className="datafield"
  type="email"
  id="email"
  {...register("email")}
/>
<br />
<label className="labclass" htmlFor="phone">
Phone*
</label>
<br />
<input
  className="datafield"
  type="phone"
  id="phone"
  {...register("phone")}
/>
<br />
<label className="labclass" htmlFor="furtherdetails">
Further Details
</label>
<br />
<input
  type="text"
  id="furtherdetails"
  {...register("furtherdetails")}
  className="datafield"
  style={{ width: "100%", height: "100px", resize: "vertical" }} // Style for better usability
  rows="7" // You can adjust the rows and cols to fit your needs
  cols="80"
/>



            <div
              style={{
                color: "white",
                fontSize: "16px",
              }}
            >
              <p>
                Noble Shipping Agency is committed to protecting and respecting
                your privacy, and we’ll only use your personal information to
                administer your account and to provide the products and services
                you requested from us. From time to time, we would like to
                contact you about our products and services, as well as other
                content that may be of interest to you. If you consent to us
                contacting you for this purpose, please tick below to say how
                you would like us to contact you:
              </p>
              <br />
              <p>
                By clicking submit below, you consent to allow Noble Shipping
                Agency to store and process the personal information submitted
                above to provide you the content requested.
              </p>
            </div>
              <br />
            <button>Submit</button>
            <br />
              <br />
          </form>
          <DevTool control={control} />
        </div>
      </div>
    </GetaQuoteCon>
  );
}

export default GetaQuote;

const GetaQuoteCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    color: black;
    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  button {
    position: relative;
    bottom: 0px;
    /*     width: 10%; */
    background: orangered;
    color: white;
    cursor: pointer;
    padding: 12px;
    border-radius: 5px;
  }
  button:hover {
    background: green;
  }
  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  .datafield {
    width: 100%;
    height: 30px;
    border-radius: 5px;
  }

  form {
    background-color: rgb(20, 20, 66);
    color: rgb(170, 237, 11);
    line-height: 30px;
    padding-left: 10px;
    border-radius: 10px;
  }
  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
    .datafield {
      width: 90%;
      height: 30px;
      border-radius: 5px;
    }
  }
`;
