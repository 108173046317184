import React from "react";
import styled from "styled-components";
import img1 from "../images/hsgoods.png";

import bkgroundimg from "../images/Household-Goods-Moving1.jpg";
function DoortoDoor() {
  return (
    <HsegoodsMovCon>
      <div className="bkgpic_msg">
        <div className="message"></div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | House Goods Moving
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          <h3>
            Household shifting a comfortable and hassle free process by our
            household shifting service.
          </h3>
          The household goods shifting service includes shifing of products like
          furniture, glass items, paintings, Almira’s, electronic equipments,
          television, microwave oven, soft items like glassware & dishes and
          sensitive electronic items. All the goods are packed with appropriate
          materials such as thermocol, cardboard sheets, bubble packs, cartons,
          gunny bags & wooden crates. We Maruti Domestic Packers and Movers are
          providing our clients with excellent Packing and Unpacking Services
          that are required during relocation. These minimize the stress level
          and the chance of any kind of damage of products. Our team of experts
          is well versed with packing and unpacking of all types of goods as per
          the specifications detailed by the clients. Unlike the other packers
          and movers companies, we believe in carrying out the entire work in an
          extremely systematic manner. We understand that relocation is not an
          easy job. Therefore, we believe in maximizing the comfort of our
          customers by providing door to door service. We take the goods from
          one place and deliver it in the specified place as mentioned by the
          client making us one of the most reliable service providers in Ghana.
          The address is well located by us and all the initiatives are taken by
          us in terms of timely delivery. We ensure that our door to door
          service helps and provides facilitation to the client with no delays.
          We Maruti Domestic Packers and Movers offer fleet and adequate home
          relocation solution. Understanding the value of every single item of
          yours, we pack commodities in durable materials like wooden crates,
          cartons, pallets, metal or plastic drums unlike many of the already
          existing companies listed under the same category as ours. Office
          relocation involves shifting of different official things such as
          computers, furniture, important files and documents. Packing and
          unpacking of items is performed by a team of experts by making use of
          modern day equipment, thereby we carry out this task in a very
          efficient way. We Packers and Movers offers authentic and efficient
          car carrier service that helps in speedy, swift and safe transit of
          cars and other vehicles at the desired terminal. We promise and ensure
          to offer modern technology devices such as fleet of trailers, car
          carrier trucks, containers and canters that would add on to maintain
          the safety of cars and other vehicles. Our company understands that
          car is the most expensive possession for anyone and special care has
          to be given to it while shifting it to some other place.
        </div>
      </div>
    </HsegoodsMovCon>
  );
}

export default DoortoDoor;

const HsegoodsMovCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    margin-left: -300px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
