import React from "react";
import styled from "styled-components";
import img1 from "../images/ProjectC2.jpg";
import img2 from "../images/ProjectC3.jpg";
import img3 from "../images/project_cargo1.jpg";

import bkgroundimg from "../images/pcargo1.jpg";
function ProjectCargo() {
  return (
    <ProjectCargoCon>
      <div className="bkgpic_msg">
        <div className="message">PROJECT CARGO</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Project Cargo
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          <h3>Your Trusted Partner for Industrial-Sized Projects</h3>
          <p>
            At Noble Shipping, we have built a reputation for excellence in
            handling complex and industrial-sized projects. Our dedicated
            project logistics department, operating in close collaboration with
            our global network of offices, is staffed with highly experienced
            professionals who are committed to finding solutions for your unique
            moves. Whether it's ocean, air, heavy haul or barge we have the
            expertise and resources to safely deliver your cargo anywhere in the
            world. Projects are not just a job for us; they are our tradition.
          </p>
        </div>

        <div className="desc-container">
          <h3>Unleash the Power of Specialized Transport</h3>
          <p>
            Unleash the Power of Specialized Transport Noble Shipping is a
            leader in providing specialized transport services for heavy-lift
            cargo. With 100 offices worldwide, we tailor transport solutions
            with a variety of modes to meet your unique requirements. Whether
            it's ocean, air, heavy-haul, barge, or rail transport, we have the
            knowledge and experience to safely deliver your freight anywhere in
            the world. Our specialized transport expertise covers a wide range
            of components, including chemical plants, gas plants, power
            generation equipment, modules, and other out-of-gauge cargo.
          </p>
        </div>
        <div className="image-container">
          <img className="image" src={img2} alt=" " />
        </div>
        <div className="image-container">
          <img className="image" src={img3} alt=" " />
        </div>
        <div className="desc-container">
          <h3> Empowering Industrial Progress </h3>
          <p>
            Industrial projects, refineries, and large-scale constructions
            demand precise transport engineering, meticulous management, and
            flawless execution. With our global presence of 50 offices across 30
            countries, comprehensive capabilities in all modes of transport, and
            a dedicated project team, Fracht is equipped to handle logistically
            challenging projects with ease. We uphold the highest standards of
            health, safety, and environmental certifications, ensuring that
            complex components are delivered with the quality and safety our
            clients expect.
          </p>
        </div>
      </div>
    </ProjectCargoCon>
  );
}

export default ProjectCargo;

const ProjectCargoCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;

    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
