import React from "react";
import styled from "styled-components";
import img1 from "../images/ceo.jpeg";
import img2 from "../images/firstdirector.jpeg";
import img3 from "../images/secondirector.jpeg";

import bkgroundimg from "../images/abus.jpg";
function AboutUs() {
  return (
    <AboutUsCon>
      <div className="bkgpic_msg">
        <div className="message">Your text message goes here!</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | About Us
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          As the CEO of Noble Shipping Ageny, it’s my pleasure to welcome you to
          our family of clients, partners, and team members who collectively
          strive to make logistics seamless and efficient every day. Freight
          forwarding is the backbone of the global economy, bridging distances
          and bringing the world closer together. At Noble Shipping Agency, we
          are proud to play a critical role in this process by ensuring that
          your goods reach their destination securely, swiftly, and in perfect
          condition. Our mission is grounded in more than just moving goods;
          it’s about building trust, reliability, and exceptional service in
          every shipment. Our commitment to innovation, sustainable practices,
          and customer-first values empowers us to meet the evolving needs of
          our clients and partners, no matter where they are in the world. Thank
          you for choosing Noble Shipping Agency. We look forward to supporting
          your journey, as we transport not only goods but also success, across
          borders and beyond. Together, let’s move the world.
        </div>
        <div className="desc-container">
          We are committed to delivering efficient, reliable, and tailored
          logistics solutions that meet the dynamic needs of your business. Our
          team of experienced professionals is dedicated to ensuring that your
          goods are transported safely, on time, and in full compliance with
          international regulations. Whether you're shipping locally or
          globally, our comprehensive suite of services—including ocean freight,
          air cargo, customs clearance, and door-to-door delivery—ensures that
          your supply chain remains seamless and optimized. At Noble Shipping
          Agency, we value the relationships we have built with our clients, and
          we continuously strive to exceed your expectations. We are here to
          support your business and provide solutions that drive your success.
          Thank you for trusting us with your logistics needs. We look forward
          to serving you and becoming a trusted partner in your global trade
          journey.
        </div>
        <div className="image-container">
          <img className="image" src={img2} alt=" " />
        </div>

        <div className="image-container">
          <img className="image" src={img3} alt=" " />
        </div>
        <div className="desc-container">
          We pride ourselves on our commitment to excellence, ensuring that your
          shipments are handled with the utmost care and delivered on time. Our
          dedicated team of professionals works tirelessly to streamline
          logistics, optimize routes, and offer personalized services that set
          us apart in the industry. We understand the importance of your cargo
          and strive to exceed your expectations with every shipment. Thank you
          for choosing Noble Shipping as your trusted freight forwarding
          partner. We look forward to continuing our successful journey together
          and achieving new heights of service excellence.
        </div>
      </div>
    </AboutUsCon>
  );
}

export default AboutUs;

const AboutUsCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }


  .message {
    font-weight: bold;
    margin-left: -300px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }

  .image-container .image {
    width: 20vw;
    height: 40vh;
    border-radius: 50%;
    border: 2px solid gainsboro;
    align-items: center;.
       justify-content: center;

  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 50vw;
      height: 25vh;
      justify-content: center;
      align-items: center;
      border: 2px solid gainsboro;
      border-radius: 50%;
      align-items: center;

  }

    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
