import React from "react";
import styled from "styled-components";

import bkgroundimg from "../images/ff.avif";
function FreightForwarding() {
  return (
    <FreightForwardingCon>
      <div className="bkgpic_msg">
        <div className="message"></div>
      </div>
      <a className="globalhome" href="/">
        Global Home
      </a>
      | Freight Forwardering
      <div className="imgandesc">
        <div className="w_2">
          <h1>
            <center>
              Optimising Your Logistics with Noble shipping Solutions
            </center>
          </h1>
          <br />
          <br />
          Freight forwarding is a crucial aspect of logistics, particularly in a
          region as dynamic as West Africa. At Noble Shipping Agency, we
          specialise in streamlining and enhancing the movement of cargo into,
          around, and out of Southern Africa. Our expertise in clearing and
          forwarding, warehousing, and project logistics ensures a seamless and
          efficient supply chain. Explore the importance of freight forwarding
          Ghana below.
          <br />
          <br />
          <h3> What is Freight Forwarding?</h3>
          <br />
          Freight forwarding involves the coordination and shipment of goods
          from one place to another via single or multiple carriers. This
          process includes various services such as:
          <ul>
            Transport planning
            <li>Customs documentation and clearance</li>
            <li> Warehousing</li>
            <li>Cargo insurance </li>Tracking and monitoring of shipments
          </ul>
          At Noble Shipping, we ensure that your goods are transported
          efficiently and safely. <br />
          <br />
          <h3>The Importance of Freight Forwarding Ghana</h3>
          <br /> Economic Growth and Trade Facilitation When it comes to Freight
          Forwarding Ghana, a reliable freight forwarder is key in the regional
          and global trade landscape. Efficient freight forwarding is vital for
          supporting economic growth and enhancing trade facilitation. By
          ensuring the smooth movement of goods, freight forwarders help
          stimulate economic activities and the meticulous management of cargo
          movements fosters better trade relationships with international
          partners. <br /> <br />
          <h4>Navigating Complex Logistics </h4>
          <br />
          <h4>Freight Forwarding Ghana</h4> does present unique logistical
          challenges, including varying regulations, infrastructural
          limitations, and geographic obstacles. Luckily, OneLogix Cargo
          Solutions understands this well and as an experienced freight
          forwarder, we can help you navigate these complexities by providing
          expertise in local regulations and optimise your route planning. We
          ensure compliance with customs and other regulatory requirements and
          are able to identify the most efficient and cost-effective routes for
          your cargo.
          <br />
          <br />
          Noble Shipping Agench stands out as a leader in Freight Forwarding
          Ghana, offering comprehensive, reliable, and innovative solutions to
          streamline your cargo movements. By partnering with us, you can
          enhance your supply chain efficiency, reduce costs, and ensure your
          goods reach their destination safely and on time.
          <br />
          <br />
          <p>Seamless Clearing & Forwarding. But better!</p>
          <br />
          <br />
          <br />
          <br />
        </div>
        <div className="desc-container w_3">
          {/*  <div>
            Marketing
            <hr style={{ width: "12%" }} />
          </div>
          <div>
            <p>
              <h4>Speed and Safety</h4> We are fast, reliable and safe
            </p>
          </div> */}
        </div>
      </div>
    </FreightForwardingCon>
  );
}

export default FreightForwarding;

const FreightForwardingCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }

  .desc-container {
    width: 75vw;
    margin-left: calc((100vw - 75vw) / 2);
    margin-right: calc((100vw - 75vw) / 2);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 2px;
  }
  .imgandesc .desc-container {
    padding: 20px;
    /*     background: gainsboro; */

    text-align: justify;
  }

  .imgandesc .w_2 {
    grid-column: span 2;
    width: 75vw;
    margin-left: calc((100vw - 75vw) / 2);
    margin-right: calc((100vw - 75vw) / 2);
    text-align: justify;
    padding-left: 30px;
  }

  .desc-container div {
    background-color: rgba(2, 17, 27, 0.07);
    padding: 10px;
    border-radius: 5px;
  }

  .desc-container .w_3 {
    grid-column: span 3;
  }
  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
