// menuItems.js
export const menuItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About Us",
    submenu: [
      {
        title: "Who we are",
        url: "/AboutUsCon",
      },
      {
        title: "The Team",
        url: "/TeamCon",
      },

      {
        title: "Vision & Mission",
        url: "/about/vision-mission",
      },
      {
        title: "Vacancies",
        url: "/VacanciesCon",
      },
    ],
  },
  {
    title: "Services",
    submenu: [
      {
        title: "Project Cargo",
        url: "/ProjectCargoCon/",
      },
      {
        title: "Door to Door",
        url: "/DoortoDoorCon",
      },
      {
        title: "Packages and Parcel",
        url: "/PackPercelCon",
      },
      {
        title: "Transborder",
        url: "/TransborderCon",
      },
      {
        title: "Customs Brokage",
        url: "/CustomsBrokageCon",
      },
      {
        title: "Warehouse",
        url: "/WareHouseCon",
      },
    ],
  },
  {
    title: "Special Solutions",
    submenu: [
      {
        title: "HealthCare",
        url: "/HealthcareCon",
      },
      {
        title: "Food",
        url: "/FoodCon",
      },

      {
        title: "Online Purchase",
        url: "/PurchaseOnlineCon",
      },
      {
        title: "Household Goods Moving",
        url: "/HsegoodsMovCon",
      },
      {
        title: "Corporate Relocation",
        url: "/CorpRelocCon",
      },
      {
        title: "Moving To Ghana",
        url: "/MovGhCon",
      },
    ],
  },
  {
    title: "Resources",
    submenu: [
      {
        title: "Incoterms",
        url: "/incotermsCon",
      },
      {
        title: "Dangerous Goods",
        url: "/DangrousGoodsCon",
      },
      {
        title: "Get a quote",
        url: "/GetaQuoteCon",
      },
      {
        title: "Freight Forwarding",
        url: "/FreightForwardingCon",
      },
      {
        title: "Moving Guide & Tips",
        url: "/MovingTipsCon",
      },
    ],
  },
  {
    title: "Login/Sign Up",

    url: "/LoginCon",
  },
];
