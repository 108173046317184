import React from "react";
import styled from "styled-components";
import noblelogo from "../images/noblelogo.png";
import { FaUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
function Login() {
  return (
    <LoginCon>
      <form action="">
        <h2>LOGIN</h2>
        <div>
          <a href="/" className="logo">
            <img src={noblelogo} id="noblelogo" alt="Noble Logo" />N
            <span id="namest">OB</span>LE SHIPPING AGENCY
          </a>
        </div>
        <div className="input-box">
          <input type="text" placeholder="Username' required" />
          <FaUser className="icon" />
        </div>
        <div className="input-box">
          <input type="text" placeholder="Password' required" />
          <FaLock className="icon" />
        </div>

        <div className="remember-forget">
          <label>
            <input type="checkbox" />
            Remember me
          </label>
          <a href="#">Forgot password?</a>
        </div>
        <button type="submit">Login</button>
        <div className="register-link">
          <p>
            Don't have an account?<a href="#">Register</a>
          </p>
        </div>
      </form>
    </LoginCon>
  );
}

export default Login;

const LoginCon = styled.div.attrs({
  className: "wrapper",
})`
  /* Styles for the wrapper class */
  display: flex;
  color: white;
  border: 2px soild orange;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #333;
  margin-top: -100px;
  padding: 30px 40px;

  #noblelogo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    vertical-align: middle;
    padding-right: 2.5px;
  }

  #namest {
    color: orangered;
    background: black;
    border-radius: 50%;
    font-weight: bold;
    border: 2px solid white;
  }
  /* Nested styles for elements inside the wrapper */
  .input-box {
    /* Additional styles for the wrapper class */
    border: 2px solid #000;
    position: relative;
    width: 100%;
    height: 50px;

    margin: 30px 0px;
  }
  .input-box input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    outline: none;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 40px;
    font-size: 16px;
    color: #fff;
    padding: 20px 45px 20px 20px;
  }

  .input-box input::placeholder {
    color: white;
  }

  .input-box .icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
  }

  .remember-forget {
    display: flex;
    justify-content: space-between;
    font-size: 14.5px;
    margin: -15px 0 15px;
  }
  .remember-forget a {
    color: #fff;
    text-decoration: none;
  }

  .remember-forget a:hover {
    text-decoration: underline;
  }
  button {
    width: 100%;
    height: 35px;
    background: #fff;
    border: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    font-weight: 700;
  }
  .register-link {
    text-align: center;
    font-size: 15.5px;
    margin: 20px 0 15px;
  }
`;
