import projectcargo from "../images/projectcargo.jpg";
import housemoving from "../images/bannerHouseholdGoods.jpg";
import cusbrokage from "../images/custom_brokage_5.png";
import airfreight from "../images/airfreight.jpg";
import doortodoor from "../images/doortodoor.png";
import percel from "../images/parcel.jpg";
import freightfwd from "../images/oceanship.jpg";
import transport from "../images/heavy-cargo2.jpeg";
import warehouse from "../images/warehouse.avif";
import corperel from "../images/corper1.jpg";

export const ServicesData = [
  {
    id: "svr1",
    ServiceName: "Project Cargo",
    /*   price: 45, */
    desc: "Project cargo is the transportation of any item that is too big or too heavy to fit into an ocean freight container or a truck trailer. These oversize and heavy items are often complex to transport and require an individualized plan to move domestically or internationally",
    img: projectcargo,
    LearnMore: `/ProjectCargoCon`,
  },
  {
    id: "svr1",
    ServiceName: "Package and Percel",
    /*   price: 45, */
    desc: "Package and Pacel services are essential for modern logistics, ensuring timely delivery of goods worldwide. They offer convenience and security, making them indispensable for e-commerce and personal shipping needs, bridging distances efficiently and reliably.",
    img: percel,
    LearnMore: `/PackPercelCon`,
  },
  {
    id: "svr1",
    ServiceName: "Customs Clearance",
    /*   price: 45, */
    desc: "Noble Shipping is a licensed Customs Broker with the Ghana Customs Division Services. Unlike many logistics companies that sub-contract this business, we can control and handle all the intricacies of the importation process",
    img: cusbrokage,
    LearnMore: `/CustomsBrokageCon`,
  },
  {
    id: "svr1",
    ServiceName: "Door to Door",
    /*   price: 45, */
    desc: " Our door-to-door ocean freight service simplifies the shipping process by managing every aspect of your shipment from pick-up to delivery. We handle all necessary documentation, customs clearance, and logistics coordination, allowing you to focus on your core business activities.",
    img: doortodoor,
    LearnMore: `/DoortoDoorCon`,
  },
  {
    id: "svr1",
    ServiceName: "House Moving",
    /*   price: 45, */
    desc: "Since 1992, Noble Shipping Agency has been the residential moving company exceeding customer expectations with professional, comprehensive household moving services around the country and worldwide.",
    img: housemoving,
    LearnMore: `/HsegoodsMovCon`,
  },
  {
    id: "svr1",
    ServiceName: "Ocean Freight",
    /*   price: 45, */
    desc: "We work closely with shipping companies since decades. FCL, LCL or oversized items, we ship all kind of goods from and to all mains container terminals in the world.",
    img: freightfwd,
    LearnMore: `/OceanFreightCon`,
  },
  {
    id: "svr1",
    ServiceName: "Air Frieght",
    /*   price: 45, */
    desc: "When you need a fast, economical, and reliable solution to move your goods from Ghana to the rest of the world, it's time to rely on us - Noble Shipping - to efficiently deliver them. We offer quick air freight solutions.",
    img: airfreight,
    LearnMore: `/AirFreightCon`,
  },
  {
    id: "svr1",
    ServiceName: "Transborder",
    /*   price: 45, */
    desc: "Transborder dynamics shape global interactions, driving international trade, influencing cultural exchanges, and fostering economic growth. These activities enhance collaboration",
    img: transport,
    LearnMore: `TransborderCon`,
  },

  {
    id: "svr1",
    ServiceName: "Warehouse",
    /*   price: 45, */
    desc: "You need a temporary storage service or a warehousing service under a free trade zone. Contact for further information about price & conditions",
    img: warehouse,
    LearnMore: `/WareHouseCon`,
  },
  {
    id: "svr1",
    ServiceName: "Relocation Services",
    /*   price: 45, */
    desc: "Our corporate relocation services are designed to make moving your business operations and employees as smooth and efficient as possible. We handle the logistics of transporting office furniture, equipment, and personal belongings across local and international locations. ",
    img: corperel,
    LearnMore: `CorpRelocCon`,
  },
];
