import React from "react";
import MenuItem from "./MenuItem.js";

const Dropdown = ({ submenu, dropdown, depthLevel }) => {
  const newDepthLevel = depthLevel + 1;
  const dropdownClass = newDepthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul className={`dropdown ${dropdownClass}} ${dropdown ? "show" : ""}`}>
      {submenu.map((submenu, index) => (
        <MenuItem items={submenu} key={index} depthLevel={newDepthLevel} />
      ))}
    </ul>
  );
};

export default Dropdown;
