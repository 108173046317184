import React from "react";
import styled from "styled-components";
/* import img1 from "../images/movetoghpic.jpg"; */
import img2 from "../images/moveghpicma1p.jpg";

import bkgroundimg from "../images/moveghpicmap.jpg";
function MovGh() {
  return (
    <MovGhCon>
      <div className="bkgpic_msg">
        <div className="message">Moving To Ghana</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Door to Door
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img2} alt=" " />
        </div>
        <div className="desc-container">
          <h2>Moving to Ghana</h2>
          <p className="redtext">
            Are you preparing to move to Ghana due to family affairs or business
            shifting? Are you looking for a reliable and cost-effective moving
            company to assist you in overseas shipping to Ghana?
          </p>
          <p>
            Integral Freight is the answer to your moving needs from the US to
            Ghana. Whether you need to move a few personal belongings or your
            entire home, including electronic appliances, furniture, and other
            items from the US to Ghana, we offer reliable and insured door to
            door moving services.
          </p>
          <p className="redtext">
            Full Range of Professional Shipping Services to Ghana
          </p>
          With extensive experience in international shipping, we offer a full
          range of moving services, including packaging, transportation,
          clearance of international customs and delivery to your address in
          Ghana. Our team has professional expertise and experience in
          international moving. We provide you with a seamless and hassle-free
          relocation experience to Ghana.
          <p className="redtext">
            Offering Single Point of Contact for All Your Relocation Needs
          </p>
          Relocation to Ghana can be an overwhelming task. If you are making all
          the arrangements by yourself, it can take your plenty of valuable
          time, effort, and money. To make the entire task of moving to Ghana
          quick, hassle-free, and cost-effective, we offer a single point of
          contact. You need not contact multiple services to arrange your
          overseas shipping to Ghana.
          <p className="redtext">
            Serving You From Departure to Final Destination
          </p>
          Our experts provide you professional assistance from the time you
          contact us with your relocation needs until your goods are delivered
          to the final destination. We ensure all your requirements are
          efficiently met from the beginning until the final delivery of your
          shipment. Through our state-of-the-art international shipping
          management software and expertise of our agents, you get world-class
          shipping services to Ghana from the US. Keep track of Your Shipment
          You can keep track of your shipment throughout its journey as we
          provide you with an exclusive tracking ID. Our experts work with you
          at every step to answer your queries, clear your international customs
          and safely deliver your belongings to the desired destination.
          <p className="redtext">
            Give us a call now to get a FREE estimate for stress-free, quick and
            efficient shipping service to Ghana!
          </p>
        </div>
      </div>
    </MovGhCon>
  );
}

export default MovGh;

const MovGhCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;

    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
