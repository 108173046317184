import React from "react";
import styled from "styled-components";
import img1 from "../images/ff.jpeg";

import bkgroundimg from "../images/ocfreight.avif";

function OceanFreight() {
  return (
    <OceanFreightCon>
      <div className="bkgpic_msg">
        <div className="message"></div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Ocean Freight
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          International ocean freight shipping with Noble Shipping is the most
          efficient and stress-free means of transporting your goods by sea to
          anywhere in the world. We are a globally renowned ocean freight
          forwarder and logistics service company with offices in North America
          and Asia. By developing international freight supply chain logistics
          solutions, we can provide cost-effective freight shipping rates and
          shipping services to our valued clients. Our freight forwarding
          experts are available to navigate the ins and outs of international
          sea freight for you. We provide extensive consulting services for all
          our clients to help you understand your financial transactions with an
          in-depth cost analysis. Noble Shipping offers a variety of services,
          from ocean freight solutions to warehousing to order fulfillment. We
          work with numerous global partners, building international
          relationships that help us provide the best shipping services. Our
          freight brokers assist clients in making important decisions
          concerning best shipping practices. Here are several ways we can help
          you plan a shipment efficiently. <h3>Freight Consolidation</h3>
          Options When shipping via boat, train, or truck, most freight
          forwarders and logistics carriers charge per container, so sending a
          full container load (FCL) is preferable. However, if you have less
          than a container load (LCL), a freight forwarding service allows you
          to consolidate shipments by combining orders from two or smaller
          shippers for an FCL. Shipping an FCL is much more cost-effective and
          allows smaller shipments to be transported more quickly. At Noble
          Shipping, we work with many customers and shippers to consolidate and
          organize shipments in real-time, providing prompt and efficient
          service. Using current technology, we also keep you informed of your
          shipment status.
          <h3>Complete Container Specifications </h3>For those seeking freight
          transportation for an FCL or LCL ocean international shipping, our
          team can reliably provide you with the information you need to
          prepare, pack, transport, and ship your cargo. Your shipment must be
          palletized or packed carefully to comply with international customs
          regulations and make it safely to its final destination. This may not
          be much of an issue for most goods, but special considerations may
          need to be made ahead of time for odd-sized or heavy goods and
          hazardous materials. Negotiating Contracts At Noble Shipping, we can
          find you the best freight quote possible, ensuring you get the lowest
          prices for the service provided. We have developed relationships with
          freight forwarders and carriers worldwide and negotiate on your behalf
          to get the best shipping rates. By working with a vast network of
          movers and shippers, we can compare rates in real-time, providing
          users with options based on the priority of shipping time, cost, or
          method. Ocean shipping is one of the most cost-effective ways to ship,
          but it takes the longest. Booking Shipments with Ocean Freight
          Carriers As your ocean freight consolidator, we do the work of booking
          your shipment for you. While it is possible to book ocean cargo
          freight services directly, working with a qualified freight forwarder
          can save you time and money, and provide you with the support you need
          during the process. Our business is built on existing relationships,
          and we can often provide speedy service when it comes to booking,
          modifying, or canceling a shipment. We’ll also keep you updated every
          step of the way so you know exactly where your shipment is. Handling
          Import and Export Documentation When shipping goods internationally,
          you must deal with import and export documentation. Noble Shipping
          also offers customs brokerage services, handling duties, and fees
          associated with your shipment. In addition, when shipping to foreign
          countries, customs clearance documentation can be in a foreign
          language, and our trained professionals accurately handle the
          paperwork for you. Packaging Requirements When shipping via ocean,
          ground, or air freight, you must ensure your shipment follows specific
          packing requirements. Items of certain sizes, weights, and dimensions
          may need special shipping requirements. Let us assist you in preparing
          your cargo for your ocean shipment, considering shipping timelines,
          climate control needs, stackability, and transporting sensitive items.
        </div>
      </div>
    </OceanFreightCon>
  );
}

export default OceanFreight;

const OceanFreightCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    margin-left: -300px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
