import React from "react";
import styled from "styled-components";
import img1 from "../images/dd.jpg";
/* import img2 from "../images/ProjectC3.jpg";
import img3 from "../images/project_cargo1.jpg"; */
/* import cus from "../images/door2todoor.jpg"; */
import list from "../images/list.png";
import delv from "../images/delivery.png";
import pickup from "../images/pickup.png";

import bkgroundimg from "../images/dd.webp";
function DoortoDoor() {
  return (
    <DoortoDoorCon>
      <div className="bkgpic_msg">
        <div className="message">
          Door to Door Shipping
          <p style={{ fontSize: 20, color: "black" }}>
            We pick up packages anywhere in the US, UK, France, Germany, and
            Canada and deliver to Ghana. We also send packages from Ghana to the
            US.
          </p>
        </div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Door to Door
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          <div>
            <img src={list} alt="" />
            <ul>List it</ul>
            Tell us where you want an item picked up from and sent to. We will
            need the weight and dimension.
          </div>

          <div>
            <img src={pickup} alt="" />
            <ul>Item is picked up</ul>We arrange to pick up the package from the
            pick up address.
          </div>

          <div>
            <img src={delv} alt="" />
            <ul>Item is Delivered</ul>
            Noble transports the package by air or sea and delivers the package
            to the door of the destination address
          </div>
        </div>
      </div>
    </DoortoDoorCon>
  );
}

export default DoortoDoor;

const DoortoDoorCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  img {
    width: 50px;
    height: 50px;
  }
  .message {
    font-weight: bold;

    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
