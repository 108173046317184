import React from "react";
import styled from "styled-components";
import bkgroundimg from "../images/termpetan.avif";
function TermsAndCond() {
  return (
    <TermsAndCondCon>
      <div className="bkgpic_msg">
        <div className="message">Terms and Conditions</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Terms And Conditions
      <div className="imgandesc">
        <div className="desc-container">
          <h1>Terms And Conditions</h1>
          <br />
          <h2>Use and Disclosure of Personal Information</h2>
          <p>
            Noble Shipping Agencymakes no representation or warranty of any
            kind, express, implied or statutory regarding this website or the
            materials and information contained or referred to on each page
            associated with this website. The material and information contained
            on this website is provided for general information only and should
            not be used as a basis for making business decisions. Any advice or
            information received via this website should not be relied upon
            without consulting primary or more accurate or more up-to-date
            sources of information or specific professional advice. You are
            recommended to obtain such professional advice where appropriate.
          </p>
          <p>
            Geographic, political, economic, statistical, financial and exchange
            rate data is presented in certain cases in approximate or summary or
            simplified form and may change over time. Reliance has been placed
            by the editors on certain external statistical data which, though
            believed to be correct, may not in fact be accurate. Noble Shipping
            Agency accepts no liability for any loss or damage arising directly
            or indirectly from action taken, or not taken, in reliance on
            material or information contained on this website. In particular, no
            warranty is given that economic reporting information material or
            data is accurate reliable or up to date.
          </p>
          <br />
          <p>
            Noble Shipping Agencyaccepts no liability and will not be liable for
            any loss or damage arising directly or indirectly (including
            special, incidental or consequential loss or damage) from your use
            of this website, howsoever arising, and including any loss, damage
            or expense arising from, but not limited to, any defect, error,
            imperfection, fault, mistake or inaccuracy with this website, its
            contents or associated services,
          </p>
          <br />

          <p>
            Please note that any software or files downloaded from this website
            is at your own risk and Noble Shipping Agency neither assumes nor
            accepts liability for any loss or damage, (whether direct or
            indirect), howsoever caused, as a result of any computer viruses,
            trojan horses, worms, software bombs or similar items or processes
            arising from your use of this website.
          </p>
          <br />
          <p>
            Any hyperlinks from this website exist for information purposes and
            are for your convenience only and Noble Shipping Agency accepts no
            liability for any loss or damage arising directly or indirectly
            (including consequential loss) from the accuracy or otherwise of
            materials or information contained on the pages of such sites or
            loss arising directly or indirectly from defects with such sites.
            Noble Shipping Agency’ inclusion of hyperlinks does not imply any
            endorsement of the material on such sites. Noble Shipping Agency
            does not guarantee that any e-mails from the website will be sent to
            you or received by Noble Shipping Agency nor does Noble Shipping
            Agency warrant the privacy and/or security of e-mails during
            internet transmission.
          </p>
        </div>
      </div>
    </TermsAndCondCon>
  );
}

export default TermsAndCond;

const TermsAndCondCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;

    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
