import React from "react";
import styled from "styled-components";
/* import img1 from "../images/movetoghpic.jpg"; */
import img2 from "../images/moveghpicma1p.jpg";
import { GrDeliver } from "react-icons/gr";
import { BiHappyHeartEyes } from "react-icons/bi";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { MdOutlineCrueltyFree } from "react-icons/md";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";

import bkgroundimg from "../images/Whychooseus.jpg";
function WhyChooseUs() {
  return (
    <WhyChooseUsCon>
      <div className="bkgpic_msg">
        <div className="message"></div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Why Choose Us
      <div className="imgandesc">
        <div className="w_2">
          <center>
            <p>
              No two companies are alike, so when you want to ship choose the
              company synonymous with trust in the shipping and logistics space,
              Noble Shipping! Here is why;
            </p>
          </center>
        </div>
        <div className="desc-container w_3">
          <div>
            <BiHappyHeartEyes className="chooseus" />
            <p>
              <h4>Great Customer Experience</h4> We provide an exceptional
              customer experience
            </p>
          </div>
          <div>
            <AiOutlineSafetyCertificate className="chooseus" />
            <p>
              <h4>Speed and Safety</h4> We are fast, reliable and safe
            </p>
          </div>
          <div>
            <FaPhone className="chooseus" />
            <p>
              <h4>Seamless Communication</h4> We have eyes & ears at every step
              of the supply chain so you can know the status of your shipment &
              relay accurate stakeholder information with ease & efficiency.
            </p>
          </div>
          <div>
            <FaFileInvoiceDollar className="chooseus" />
            <p>
              <h4>Cost Effective</h4> Our rates are competitive and transparent.
              We unify your invoices & cut out the middle man to improve your
              cost transparency & uncover savings opportunities that make a big
              impact on your cost management.
            </p>
          </div>
          <div>
            <MdOutlineCrueltyFree className="chooseus" />
            <p>
              <h4>Stress Free</h4> We handle customs clearance and all
              applicable taxes for you
            </p>
          </div>
          <div>
            <GrDeliver className="chooseus" />
            <p>
              <h4>Destination Delivery</h4> We deliver to your door – business
              and residential
            </p>
          </div>
        </div>
      </div>
    </WhyChooseUsCon>
  );
}

export default WhyChooseUs;

const WhyChooseUsCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }

  .desc-container {
    width: 75vw;
    margin-left: calc((100vw - 75vw) / 2);
    margin-right: calc((100vw - 75vw) / 2);
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 50px;
  }
  .imgandesc .desc-container {
    padding: 20px;
    /*     background: gainsboro; */

    text-align: justify;
  }

  .imgandesc .w_2 {
    grid-column: span 2;
  }
  .chooseus {
    font-size: 60px;
    border: 2px solid blue;
    border-radius: 50%;
    left: 50%;
  }

  .desc-container div {
    background-color: rgba(2, 17, 27, 0.07);
    padding: 10px;
    border-radius: 5px;
  }

  .desc-container .w_3 {
    grid-column: span 3;
  }
  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
