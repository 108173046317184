import React from "react";
import styled from "styled-components";
import img1 from "../images/producefood1.jpg";
import img2 from "../images/foodtemp.avif";
import bkgroundimg from "../images/productshipping1.png";
import noblepro from "../images/fruit.jpg";

function ProduceShipping() {
  return (
    <ProduceShippingCon>
      <div className="bkgpic_msg">
        <div className="message">Produce Shipping</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Custom Brokage
      <div className="imgandesc">
        <div className="desc-container">
          <h1>
            Grow Your Business with High-Quality Produce Shipping Tailored to
            Your Needs
          </h1>
          Find out why fresh food distributors, growers, restaurants, and
          businesses around the world trust NOBLE’s produce shipping experts to
          deliver a world-class experience every time. 20+ years of produce
          shipping experience 130+ location global network Multiple
          accreditations, certifications Crating, labeling, insulated packing
          and more Faster, flexible, and more affordable food logistics
          expertise Where other multinationals expect customers to conform to
          their processes,NOBLE designs custom-fit client solutions—and
          maintains accountability A hands-on, personal approach to client
          relationships and plan development
          <br />
          <br />
          <h3>Enjoy worry-free shipping via a global cold-chain network</h3>
          <br />
          NOBLE’s locations around the world fuse innovative technology and
          well-tested procedures to ensure your fruits and vegetables are
          delivered to their destination quickly, using the most efficient modes
          of transit, to maintain the highest quality possible.
          <br />
          <br />
          <h3>Delight your customers with industry-leading quality</h3>
          <br />
          Combined with decades of time-tested shipping expertise in the produce
          market managing the shipments of a broad range of products—from stone
          fruits to grapes to avocados and beyond—AIT’s quality management
          system helps ensure your world-class produce is as delicious upon
          delivery as it was when first harvested.
        </div>

        <div className="image-container">
          <img className="image" src={img1} alt=" " />
          <br />
          <img className="image" src={img2} alt=" " />
        </div>
      </div>
      <div className="noblepro">
        <div className="messagepro">
          <h1>
            Team up with one of the industry’s leading fresh food distributors
          </h1>
          <p>
            Take advantage of our improved service experience, quality-control
            assistance, enhanced customer communications, and expansive global
            network—partner with the industry’s most trusted fresh food
            distributors and produce shipping professionals at NOBLE today. To
            learn more, get a quote or get started on your tailored produce
            distribution plan..
          </p>
          <br />
          <button>SHIP WITH NOBLE</button>
        </div>
      </div>
    </ProduceShippingCon>
  );
}

export default ProduceShipping;

const ProduceShippingCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    width: 100%;
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    color: white;
    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 25vw;
    height: 30vh;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }
  .noblepro {
    background: linear-gradient(
        to left,

        rgba(100, 100, 190, 0.1),
        rgba(0, 0, 0, 0.1) 50%
      ),
      url(${noblepro});

    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 65vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }
  .messagepro {
    background: gainsboro;
    border-radius: 150px 100px 200px 100px;
    position: relative;
    width: 50%;
    font-weight: bold;
    padding: 30px;
    text-align: right;
    margin-left: -700px;
    text-align: left;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
