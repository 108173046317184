import { pointer } from "@testing-library/user-event/dist/cjs/pointer/index.js";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";

function ShipmentDate() {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <ShipmentDateCon>
      <DatePicker
        width={"100vw"}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        placeholderText="Select a date" // Optional: Add placeholder text
        dateFormat="MMMM d, yyyy" // Optional: Format the displayed date
      />
    </ShipmentDateCon>
  );
}

export default ShipmentDate;
const ShipmentDateCon = styled.div`
 /*  .custom-datepicker {
    cursor: pointer; */
  }
`;
