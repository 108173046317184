import React from "react";
import styled from "styled-components";
import img1 from "../images/shopper.jpg";
import comimg1 from "../images/comimg1.webp";
import comimg2 from "../images/comimg2.jpg";
import comimg3 from "../images/comimg3.jpeg";
import comimg4 from "../images/coming4.jpg";
import vipbkg from "../images/vip1.avif";
import noblepro from "../images/no.jpg";
import fooshipguide from "../images/fooshipguide.jpg";
/* import noblepro from "../images/icream.jpg";
 */
import bkgroundimg from "../images/foodpic2.jpg";
function ProjectCargo() {
  return (
    <ProjectCargoCon>
      <div className="bkgpic_msg">
        <div className="message">
          <h1>Distribution made seamless with food supply chain experts</h1>
          <br />
          <p>
            From one-time sample shipments to regular, transit warehousing,
            equipment installations, and fulfillment operations, our in-depath ,
            scalable and supply chain solutions are customizable to your unique
            goal-adding value to business beyond well beyond simple shipping.
          </p>
          <br />
          <button>EXPLORE NOBLE'S FOOD LOGISTICS SOLUTIONS</button>
        </div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Food Solutions
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          <h2>Grow your bottom line with solutions customized to your goals</h2>
          Through a combination of innovative technology and time-tested food
          supply chain procedures, AIT's deeply experienced and dedicated
          professionals take the complexity out of safe, secure shipping,
          providing reliable, scalable, and customizable food supply chain
          solutions for customers around the world.
          <br />
          <br />
          <br />
          <button>EXPLORE NOBLE'S FOOD LOGISTICS SOLUTIONS</button>
        </div>
      </div>
      <br />
      <h2>Solutions for Various Commodities</h2>
      <br />
      <hr />
      <div className="FoodSolutions">
        {FoodData.map((FoodSolu) => (
          <div className="gallery-item" key={FoodSolu.id}>
            <a
              href={FoodSolu.foodurlink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="comimages">
                <img
                  className="cimg"
                  src={FoodSolu.comimage}
                  alt={FoodSolu.title}
                />
              </div>
              <div className="details">
                <br />
                <h3>{FoodSolu.title}</h3>
                <br />
                <p>{FoodSolu.msg}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
      <br />
      <div className="vip">
        <div className="messagevip">
          <h3>Make an impression with VIP influencer shipments </h3>
          Automatically include custom swag and coupons – even handwritten notes
          – to help your brand leave a mark on influential media figures.
          <button>LEARN MORE</button>
        </div>
      </div>
      <br />
      <br />
      <div className="noblepro">
        <div className="messagepro">
          <h1>Put NOBLE’s food supply chain pros to work today</h1>
          <p>
            If you know what services you’re looking for, request a quote today.
            Or get in touch with our logistics consultants for additional
            information regarding Noble's food and food supply chain solutions
            and capabilities.
          </p>
          <br />
          <button>
            <a href="/GetaQuoteCon">SHIP WITH NOBLE</a>
          </button>
        </div>
      </div>
      <br />
      Additional Resources
      <hr />
      <div className="FoodAddResInfo">
        {FoodAddResource.map((FoodAddRes) => (
          <div className="gallery-item" key={FoodAddRes.id}>
            <div className="comimages">
              <img
                className="cimg"
                src={FoodAddRes.comimage}
                alt={FoodAddRes.title}
              />
            </div>
            <div className="details">
              <br />
              <h3>{FoodAddRes.title}</h3>
              <br />
              <p>{FoodAddRes.msg}</p>
            </div>
          </div>
        ))}
      </div>
    </ProjectCargoCon>
  );
}

export default ProjectCargo;

export const FoodData = [
  {
    id: 1,
    comimage: comimg1,
    title: "Produce",
    msg: "Fresh food distributors, grower, restaurant, and businesses around the world trust Noble Shipping.",
    foodurlink: "/ProduceShippingCon",
  },

  {
    id: 2,
    comimage: comimg3,
    title: "Perishables ",
    msg: "Kitting, labelling and more for cereals, souces, pasta and more",
    foodurlink: "",
  },
  {
    id: 3,
    comimage: comimg2,
    title: "Dry Goods and Ingredients",
    msg: "   Kitting, labelling and more for cereals,spice, pasta, snack foods and more",
    foodurlink: "",
  },

  {
    id: 4,
    comimage: comimg4,
    title: "Equipment",
    msg: "From packagein materials to communications technology to entire production line, we've got you covered with logistics expertise",
    foodurlink: "",
  },
];
/****************************************************************/
export const FoodAddResource = [
  {
    id: 1,
    comimage: fooshipguide,
    title: "GUIDE",
    msg: "What You Need to Know About Food Shipping.",
    urlresouceslink: "",
  },

  {
    id: 2,
    comimage: comimg3,
    title: "List of Services",
    msg: "Kitting, labelling and more for cereals, souces, pasta and more",
    urlresouceslink: "",
  },
  {
    id: 3,
    comimage: comimg2,
    title: "Dry Goods and Ingredients",
    msg: "From fresh meat to ice cream, we deliver quality, freshness and world world",
    urlresouceslink: "",
  },
];

const ProjectCargoCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(179, 205, 224, 0.5)
      ),
      url(${bkgroundimg});

    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 60vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .vip {
    background: url(${vipbkg});
    border: 1px solid gainsboro;
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .noblepro {
    background: url(${noblepro});

    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 65vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    color: white;
    padding: 120px;
    text-align: justify;
  }
  .messagevip {
    font-weight: bold;
    font-size: 24px;
    padding: 140px;
    margin-bottom: -150px;
    text-align: justify;
    margin-left: -120px;
  }

  .messagepro {
    background: gainsboro;
    border-radius: 0px 200px 200px 0px;
    position: relative;
    width: 50%;
    font-weight: bold;
    padding: 30px;
    text-align: right;
    margin-left: -700px;
    text-align: left;
  }

  .messagepro:hover {
    background: #fffdd0;
    cursor: pointer;
  }

  button {
    position: relative;
    bottom: 0px;
    /*     width: 10%; */
    background: #002642;
    color: white;
    cursor: pointer;
    padding: 12px;
    border-radius: 5px;
  }

  button:hover {
    background: #023e7d;
  }

  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  .FoodSolutions {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
    gap: 30px;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .FoodAddResInfo {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    gap: 30px;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .cimg {
    width: 300px;
    height: 150px;
  }
  .comimages {
    width: 100px;
    height: 150px;
  }

  gallery-item @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-container {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }

    .FoodSolutions {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      gap: 30px;
    }
  }
`;
