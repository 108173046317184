import React from "react";
import Service from "./Services";
import Testimonals from "./Testimonals";
import styled from "styled-components";
import ClientSlides from "./ClientSlides";
import Faq from "./Faq";

function Home() {
  return (
    <HomeCon>
      <Service />
      <Testimonals />
      <ClientSlides />
      <Faq />
    </HomeCon>
  );
}

export default Home;

const HomeCon = styled.div`
  div button {
    cursor: pointer;
    padding: 5px 3px;
    border-radius: 5px;
  }
  @media (max-width: 700px) {
    width: 100%;
    .shortmsg {
      font-weight: bold;
      font-size: 14px;
    }
  }
`;
