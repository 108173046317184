import React from "react";

function PortofDeparture() {
  var PortofDept = ["Tema", "Takoradi", "kumasi", "Temale"];
  return (
    <select style={{ width: "100%", resize: "vertical" }}>
      {PortofDept.map((port) => (
        <option key={port} value={port}>
          {port}
        </option>
      ))}
    </select>
  );
}

export default PortofDeparture;
