import React from "react";
import styled from "styled-components";
/* import bkgroundimg from "../images/discpic.jpg"; */
function Disclaimer() {
  return (
    <DisclaimerCon>
      <div className="bkgpic_msg">
        <div className="message">Disclaimer</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Disclaimer
      <div className="imgandesc">
        <div className="desc-container">
          <h1>Disclaimer</h1>
          <br />
          <h2>Use and Disclosure of Personal Information</h2>
          <p>
            Unless restricted by applicable law, you agree that any and all
            personal information/data relating to you collected by Noble
            Shpipping Agency from this Website from time to time may be used and
            disclosed for such purposes and to such persons as may be in
            accordance with Noble Shpipping Agency’ current Data Protection and
            Privacy Policy.
          </p>
          <h2>Indemnity</h2>
          You hereby agree to indemnify and to keep Noble Shpipping Agency fully
          and effectively indemnified against any action, liability, cost,
          claim, loss, damage, proceeding or expense (including legal fees,
          costs and expenses on a full indemnity basis) suffered or incurred by
          Noble Shpipping Agency arising from or which is directly or indirectly
          related to: Your access to and/or use of Noble Shpipping Agency ‘s
          Website and/or any other person or entity’s use of this service or
          WEBSITE where such person or entity was able to access the service or
          Website using your user id and/or password; or Any breach or
          non-observance of any of these terms and conditions by you or by any
          other person or entity where such person or entity was able to access
          Website by using your user id and login password.
          <h2>Modification</h2> Noble Shpipping Agency reserves the right in its
          discretion to change without prior warning or notice any information
          or material contained on this Website and the terms and conditions
          under which this Website is used.
          <h2>Intellectual Property Rights</h2>
          <p>
            All contents of this Website including, but not limited to the text,
            graphics, links and sounds are the copyright of Noble Shpipping
            Agency Limited and may not be copied, downloaded, distributed or
            published in any way without the prior written consent of Noble
            Shpipping Agency.
          </p>
        </div>
      </div>
    </DisclaimerCon>
  );
}

export default Disclaimer;

const DisclaimerCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-color: #333;
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    color: white;
    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
