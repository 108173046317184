import React from "react";
import styled from "styled-components";

function FeedBack() {
  return (
    <FeedBackCon>
      <button>
        <a href="/">
          <span class="rotated-text">feedback</span>
        </a>
      </button>
    </FeedBackCon>
  );
}

export default FeedBack;

const FeedBackCon = styled.div`
  .rotated-text {
    display: inline-block; /* Ensure the element can be transformed */
    transform: rotate(90deg); /* Rotate the element by 90 degrees */
    transform-origin: bottom left; /* Adjust the origin point if needed */
    position: fixed;
    right: 0px; /* Align the element to the right of the container */
    top: 50%; /* Center the element vertically */
    transform-origin: top right; /* Adjust the origin for correct positioning */
    transform: translateY(-50%) rotate(90deg); /* Center the element after rotation */
    background: orangered;
    color: white;
    padding: 14px 8px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 18px;
    z-index: 1000;
  }
  .rotate-text:hover {
    color: #333;
  }
`;
