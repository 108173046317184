import React, { useEffect } from "react";
import styled from "styled-components";
import noblelogo from "../images/noblelogo.png";
import Navbar from "./Navbar";

function Header() {
  useEffect(() => {
    const handleScroll = () => {
      const headerElement = document.getElementById("HeaderStyled");
      if (window.scrollY > 0) {
        headerElement.style.top = "0";
      } else {
        headerElement.style.top = "120px";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <HeadCon id="HeaderStyled">
      <Styledheader>
        <QuickInfo>
          <ul className="quickinfo">
            <li>
              Have a question?
              <ul>
                <li>Call Us 0244236013</li>
              </ul>
            </li>
            <li>
              Email:
              <ul>
                <li>nobleshipingagency@yahoo.com</li>
              </ul>
            </li>
            <li>
              Visit Our Tema Office:
              <ul>
                <li>
                  GIFF Building. First Floor Room 211/215 Near Meridian House
                </li>
              </ul>
            </li>
            <li>
              Visit Our Airport Office:
              <ul>
                <li>AFGO (Agency Block) Kotoka Int, Airport Accra.</li>
              </ul>
            </li>
            <li>
              MON - FRI:
              <ul>
                <li>8.00AM - 5.00PM</li>
              </ul>
            </li>
          </ul>
        </QuickInfo>

        <div className="nav-area">
          <div>
            <a href="/" className="logo">
              <img src={noblelogo} id="noblelogo" alt="Noble Logo" />N
              <span id="namest">OB</span>LE SHIPPING AGENCY
            </a>
            <a href="/GetaQuoteCon" id="getq">
              Get A Quote
            </a>
          </div>
          <Navbar />
        </div>
      </Styledheader>
    </HeadCon>
  );
}

export default Header;
const HeadCon = styled.div`
  width: 100%;
  background: #003554;
`;

const Styledheader = styled.header`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);
  height: 130px;
  background: #0496ff;
  /*   background: hsl(229, 54%, 51%); */
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 3px 0 rgba(0, 0, 0, 0.07);
  color: #212529;
  top: ${(props) => (props.isVisible ? "0" : "-140px")};
  transition: top 0.3s;

  #getq {
    background: orangered;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    padding: 6px 2px;
  }

  ul li {
    list-style: none;
  }
  #noblelogo {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    vertical-align: middle;
    padding-right: 2.5px;
  }
  #namest {
    color: orangered;
    background: black;
    border-radius: 50%;
    font-weight: bold;
    border: 2px solid white;
  }

  .logo {
    font-size: 20px;
    font-weight: bold;
    font-family: "Arial";
  }
  .dropdown {
    position: absolute;
    left: 100%;
    top: 0;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #0496ff;
    border-radius: 0.5rem;
    display: none;
  }

  .dropdown.show {
    display: block;
  }

  .dropdown.dropdown-submenus {
    position: absolute;
    left: 100%;
    top: 0;
  }

  @media (max-width: 700px) {
    width: 100vw;
    .nav-area {
      width: 100vw;
      margin-left: -150px;
    }

    #noblelogo {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      vertical-align: middle;
      padding-right: 2.5px;
    }
    #getq {
      font-size: 14px;
      background: orangered;
      color: white;
      font-weight: bold;
      border-radius: 5px;
      /*       padding: 6px 2px; */
    }
  }
`;

const QuickInfo = styled.div`
  display: block;

  .quickinfo {
    display: flex;
    justify-content: space-between;
    background-color: #003554;
    align-items: center;
    color: white;
    font-size: 12.5px;
    font-weight: bold;
    padding: 4px;
  }

  @media (max-width: 700px) {
    .quickinfo {
      display: none;
    }
  }
`;
