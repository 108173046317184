import React from "react";
import styled from "styled-components";
import img1 from "../images/van.jpg";

import bkgroundimg from "../images/vacan1.webp";
function Vacancies() {
  return (
    <VacanciesCon>
      <div className="bkgpic_msg">
        <div className="message">Jobs at NOBLE SHIPPING AGENCY</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Vancancies
      <div className="imgandesc">
        <div className="desc-container">
          We are proud of what makes us stand out at Noble Shipping. The concept
          of excellence is in our DNA. We recruit the best minds and invest
          heavily in capacity building and employee development, both locally
          and overseas. Our dynamic and enabling work environment provides the
          platform for our staff to work seamlessly across diverse teams. Our
          appetite for excellence sums up our ambitious and bold approach to
          working and winning together. It is what drives us to keep moving on
          in spite of challenges encountered. We view our people as our
          competitive advantage and seek to provide career growth opportunities
          for our staff by developing deep domain expertise (DDE) of our
          workforce and this we are doing through our Noble Shipping. We aim
          higher to make a real difference to the world we live in. Every day,
          everywhere, we use our Technology Solutions and Services to satisfy
          the fast-evolving requirements of the various sectors of the economy.
          We are Noble Shipping!
        </div>

        <div className="image-container">
          <img className="image" src={img1} alt=" " />
          <a href="/HowtoapplyCon">JOBS</a>
        </div>
      </div>
      <br />
    </VacanciesCon>
  );
}

export default Vacancies;

const VacanciesCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    color: white;
    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
