import React from "react";
import styled from "styled-components";
import noblelogo from "../images/noblelogo.png";
import { FaEnvelope } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { SlSocialInstagram } from "react-icons/sl";
import { HashLink } from "react-router-hash-link";
import { BsFillArrowUpSquareFill } from "react-icons/bs";

function Footer() {
  return (
    <FooterCont>
      <div className="shortmsg">
        <div>Would you like to know more about our services?</div>
        <div>
          <button>
            <a href="/GetaQuoteCon">REQUEST FOR QUOTE</a>
          </button>
        </div>
      </div>
      <br />
      <hr />
      <div className="row">
        <div className="col">
          <a href="/#" className="logo">
            <h3>
              <a href="/" className="logo">
                <img src={noblelogo} id="noblelogo" alt="Noble Logo" />N
                <span id="namest">OB</span>LE SHIPPING AGENCY Noble Shipping
                Agency streamline and improve logistics and the way cargo is
                moved into, around & out of Ghana through a seamless integration
                of clearing and forwarding, warehousing and project logistics.
                But better!
              </a>
            </h3>
          </a>
          <ul>
            <li>Email:nobleshipingagency@yahoo.com</li>
          </ul>
        </div>
        <div className="col">
          <h3>
            About Us
            <Underline>
              <InnerSpan />
            </Underline>
          </h3>

          <ul>
            <li>
              <a href="/AboutUsCon">Who we are</a>
            </li>
            <li>
              <HashLink smooth to="/AboutUsCon/#mission">
                Our Mission
              </HashLink>
            </li>
            <li>
              <a href="/WhyChooseUsCon">Why Choose Us</a>
            </li>
            <li>
              <HashLink smooth to="/AboutUsCon/#vision">
                Vision
              </HashLink>
            </li>

            <li>
              <a href="/VacanciesCon">Vacancies</a>
            </li>
          </ul>
        </div>
        <div className="col">
          <h3>
            Quick Links
            <Underline>
              <InnerSpan />
            </Underline>
          </h3>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/CustomsBrokageCon">Customs Brokage</a>
            </li>
            <li>
              <a href="/DangrousGoodsCon">Dangrous Goods</a>
            </li>
            <li>
              <a href="/OceanFreightCon">Ocean Freight</a>
            </li>

            <li>
              <a href="/ProjectCargoCon">Project Cargo</a>
            </li>
            <li>
              <a href="/PrivacyCon">Privacy Policy</a>
            </li>
            <li>
              <a href="/TransborderCon">Transborder</a>
            </li>
            <li>
              <a href="/WarehouseCon">Warehouse</a>
            </li>
          </ul>
        </div>

        <div className="col">
          <h3>
            Resources
            <Underline>
              <InnerSpan />
            </Underline>
          </h3>

          <ul>
            <li>
              {" "}
              <a href="/IncoTermsCon">IncoTerms</a>
            </li>

            <li>
              <a href="/GetaQuoteCon">Get A Quote</a>
            </li>
            <li>
              <a href="/ContactUsCon">Contact Us</a>
            </li>
            {/*   <li>Blog</li> */}
            <li>
              <a href="/DisclaimerCon">Disclaimer</a>
            </li>
            <li>
              <a href="/TermsAndCondCon">Terms & Condition</a>
            </li>
          </ul>
        </div>

        <div className="col">
          <h3>
            News Letter
            <Underline>
              <InnerSpan />
            </Underline>
          </h3>
          <form>
            <FaEnvelope className="far fa-envelope" />
            <input type="email" placeholder="Enter Your Email" required></input>
            <button>
              <FaArrowRight className="far fa-arrow-right" />
            </button>
          </form>
          <div className="social-icons">
            <FaWhatsapp className="fab" />
            <FaFacebook className="fab" />
            <SlSocialInstagram className="fab" />
          </div>
        </div>
      </div>
      <br />
      <hr />
      <div className="cprightarro">
        <div>
          {/*    <p className="copyright"> */}© Noble Shipping Agency 2024 | All
          Right Reserved. Designed and Developed by capeltad technology.
          {/*  </p> */}
        </div>
        <div className="noblearro">
          <div>
            <a href="/">
              <img src={noblelogo} id="noblelogo" alt="Noble Logo" />N
              <span id="namest">OB</span>LE SHIPPING AGENCY
            </a>
          </div>
          <HashLink smooth to="#HeaderStyled">
            <BsFillArrowUpSquareFill id="homearrow" title="Top" />
          </HashLink>
        </div>
      </div>
    </FooterCont>
  );
}

export default Footer;

const FooterCont = styled.div`
  width: 100vw;
  position: static;
  left: 0;
  bottom: 0px;
   background-color: #00072d;
  padding: 0px 0 20px;
  border-top-left-radius: 50px;
  font-size: 15px;
  line-height: 30px;
  /*   margin-top: 20px; */
  color: white;

  .shortmsg {
    position: relative;
    with: 100%;
    border-top-left-radius: 50px;
    display: flex;
    background: #4361ee;
    height: 5vh;
    color: white;
    font-weight: bold;
    font-size: 22px;
    padding-top: 10px;
    text-align: justify;
    justify-content: space-around;
  }

  #noblelogo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    vertical-align: middle;
    border: 3px solid orangered;
  }
  .logo {
    font-size: 14px;
  }
  #namest {
    color: orangered;
    background: black;
    border-radius: 50%;
    font-weight: bold;
    border: 2px solid white;
  }

  .row {
    width: 75vw;
    margin-left: calc((100vw - 75vw) / 2);
    margin-right: calc((100vw - 75vw) / 2);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }

  ul li {
    list-style: none;
    margin-left: 0px;
  }

  ul li a {
    /*     text-decoration: none; */
    color: white;
  }

  hr {
    width: 100vw;
    border: 0.0125px solid #333;
  }

  .email_id {
    width: fit-content;
    border-bottom: 1px solid #ccc;
    margin: 20px 0;
  }

  .noblelogo {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    vertical-align: middle;
    padding-right: 5px;
    margin-left: 10px;
    border: 2px solid orangered;
  }
  .col {
    flex-basis: 20%;
    padding: 10px;
    text-align: left;
  }

  .col h3 {
    width: fit-content;
    margin-bottom: 40px;
    position: relative;
  }

  .social-icons {
    display: flex;
  }

  form {
    padding-bottom: 15px;
    display: flex;
    align-item: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    margin-bottom: 50px;
    padding-right: 15px;
  }

  .social-icons .fab {
    width: 30%;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    /* line-height: 40%; */
    /* font-size: 20px; */
    color: white;
    margin-right: 15px;
    cursor: pointer;
  }

  .copyright {
    width: 75vw;
    margin-left: calc((100vw - 75vw) / 2);
    margin-right: calc((100vw - 75vw) / 2);
  }

.cprightarro{
    width: 75vw;
    margin-left: calc((100vw - 75vw) / 2);
    margin-right: calc((100vw - 75vw) / 2);
    display: flex;
    grid-template-columns: repeat(2, 1ft);
    justify-content: space-between;
    align-items: center;

}
    .cprightarro a{
    color: white;}
    #homearrow:hover{
color: #0496ff}

.noblearro{
   display: flex;
    grid-template-columns: repeat(2, 1ft);
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

#homearrow{
width: 40px;
height: 40px;
}


  @media (max-width: 700px) {
    width: 100%;
    border-top-left-radius: 45px;

    line-height: 20px;
    position: static;
    bottom: 0;
    padding-top: 30px;
    padding-left: 0px;
    width: 100vw;
    margin-left: 0px;
    .row {
      margin-left: 0px;
    }
    .shortmsg {
      margin-top: -30px;
      font-size: 14px;
      border-top-left-radius: 50px;

  }
`;

const Underline = styled.div`
  width: 100%;
  height: 3px;
  background: orangered;
  border-radius: 3px;
  position: absolute;
  left: 0;
  overflow: hidden;
`;

const InnerSpan = styled.div`
  width: 20px;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 10px;
  animation: moving 5s linear infinite;

  @keyframes moving {
    0% {
      left: -20px;
    }
    100% {
      left: 100px;
    }
  }
`;
