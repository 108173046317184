import React, { useEffect, useState } from "react";
/* import Slider from "react-slick"; */
import styled from "styled-components";
/* import { RxFontStyle } from "react-icons/rx"; */
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
/* import { Helmet } from "react-helmet"; */
import client1 from "../images/client2.jpg";
import client2 from "../images/smailface1.jpg";
import client4 from "../images/client4.jpg";
import client3 from "../images/client3.jpg";

function Testimonals() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = testimonysliderData.length;
  const autoScroll = true;
  const intervalTime = 7000;
  let slideInterval;

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(
    () => {
      if (autoScroll) {
        auto();
      }
      return () => clearInterval(slideInterval);
    } /* [currentSlide] */
  );

  const handleRadioClick = (index) => {
    setCurrentSlide(index);
  };

  return (
    <TestimonySliderCon>
      <testhead>
        <center>
          <span>Testimonials from Our Happy Customers</span>
          <br />
          <h1>
            Join Hundres of Businesses Who Trust Our Freight Forwarding Services
          </h1>
          <p>
            Our clients’ satisfaction are our utmost priority and we strive to
            provide professional, hassle-free, safe, secure, dependable and
            timely deliveries each and every time.
          </p>
        </center>
      </testhead>

      <div className="slider">
        <FaRegArrowAltCircleLeft className="arrow prev" onClick={prevSlide} />
        <FaRegArrowAltCircleRight className="arrow next" onClick={nextSlide} />
        {testimonysliderData.map((slide, index) => {
          return (
            <div
              className={index === currentSlide ? "slide current" : "slide"}
              key={index}
            >
              {index === currentSlide && (
                <div className="texti">
                  <img src={slide.image} alt="slide" />
                  <div>{slide.msg}</div>
                  <br />
                  <div>
                    <div>{slide.title}</div>
                    <div>{slide.name}</div>
                  </div>

                  <center>
                    <GapSpan>
                      {testimonysliderData.map((_, idx) => (
                        <input
                          key={idx}
                          type="radio"
                          name="radio-btn"
                          checked={currentSlide === idx}
                          onChange={() => handleRadioClick(idx)}
                        />
                      ))}
                    </GapSpan>
                  </center>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </TestimonySliderCon>
  );
}
export default Testimonals;

export const testimonysliderData = [
  {
    image: client1,
    msg: "The team at Noble Shipping Agency is simply outstanding. From the initial quote to the final delivery, they ensure everything runs smoothly. Their personalized approach and attention to detail make them stand out from other freight forwarders we've used in the past",

    title: "Supply Chain Manager",
    name: "Emily Thompson",
    company_name: "Home Essentials Inc",
  },
  {
    image: client2,
    msg: "Noble Shipping Agency has been an invaluable partner for our business Their attention to detail and commitment to timely deliveries have significantly improved our supply chain efficiency. We highly recommend their services to anyone in need of reliable freight forwarding.",

    title: "Operations Manager",
    name: "Peter Lawson",
    company_name: "AfriTech Solutions",
  },
  {
    image: client3,
    msg: "We've been using Noble Shipping for our international shipments for over three years now. Their professionalism and dedication to custome satisfaction are unmatched. They handle all the complexities of customs and logistics, allowing us to focus on our core business.",
    title: "Marketing Manager",
    name: "Sarah Johnson",
    company_name: "Fresh Produce Distributors",
  },
  {
    image: client4,
    msg: "Noble Shipping Agency has consistently provided us with top-notch service. Their team is responsive, knowledgeable, and always willing to go the extra mile to ensure our shipments arrive on time and in perfect condition. They are a trusted partner in our supply chain.",
    title: "Procurement Director",
    name: "Gloria Silkman",
    company_name: "AlGas",
  },
];

const TestimonySliderCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);
  height: 45vh;
  background: #386fa4;
  color: white;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    padding: 3px 3px;
    color: white;
    border: 2px solid;
  }

  .texti {
    color: #fed9b7;
    font-style: italic;
    font-size: 16px;
    padding-left: 200px;
    padding-right: 200px;
    text-align: justify;
  }

  .slider {
    width: 100%;
    height: 50vh;
    position: relative;
    overflow: hidden;
    text-align: justify;
    margin-top: 40px;
    animation: slide 60s ease-in-out infinite;
  }

    .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
    height: 100%
    opacity: 0;
    transition: all 0.s ease;
   
  }

.testhead{
  margin: 40px;
  text-align: justify;
}
  
  .arrow {
    border: 2px solid #fff;
    border-radius: 50%;
    background: #333;
    color: #fff;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    top: 25%;
    z-index: 999;
  }

  .next {
    right: 2rem;
  }

  .prev {
    left: 2rem;
  }

  @media screen and (max-width: 700px) {
    width: 100vw;
    height: 70vh;
    margin-left: -60px;
    padding-right: 15px;
  

    .slide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
    height: 100%
    opacity: 0;
    transition: all 0.s ease; 
  }

 .texti {
    width: 100vw;
   
  }



}
`;

const GapSpan = styled.span`
  display: flex;
  justify-content: center;

  // Apply gap to child input elements
  input {
    margin: 0 10px; // 30px total gap between inputs (15px on each side)
    cursor: pointer;
  }
`;
