import React from "react";
import styled from "styled-components";
import img1 from "../images/packparcel-delivery.jpg";

import bkgroundimg from "../images/ppparteen.png";
function PackPercel() {
  return (
    <PackPercelCon>
      <div className="bkgpic_msg">
        <div className="message"></div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Packages and Percels
      <div className="imgandesc">
        <div className="desc-container">
          In Ghana Businesses and Companies are establishing every now and then.
          Whether moving a small office with few employees or a multinational
          Corporation with hundreds of employee moving across the city the
          process of relocation is always difficult, time consuming and
          stressful. Link World is capable of handling both internal
          (inter-office) and external (across town or across the globe). Office
          Move, Business move and corporate relocation should run as
          professionally and as economically as any successful business. Link
          World boost in working hand-in-hand with you, to provide you with
          cost-efficient business relocation from start to finish. We provide
          economical, fast/swift office moving services such as: Dismantling and
          Reassembling of office furniture’s Installation and uninstallation of
          work station. Packing of files Handling professionally for Computer
          and IT stuff Moving of IT servers
        </div>

        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
      </div>
    </PackPercelCon>
  );
}

export default PackPercel;

const PackPercelCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    margin-left: -300px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
