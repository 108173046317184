import React from "react";
import styled from "styled-components";
import img1 from "../images/airplan2.jpg";

import bkgroundimg from "../images/airplan1.jpg";
function AirFreight() {
  return (
    <AirFreightCon>
      <div className="bkgpic_msg">
        <div className="message">Your text message goes here!</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Door to Door
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          <span>
            As air cargo volumes continue to rise(Opens in a new window), don't
            risk being shut out of critical capacity. Partner with Noble
            Shipping- Agency experts and your shipments will fly securely with
            the world's leading carriers at competitive rates.
          </span>
          <div>
            We determine the most efficient and effective routing of your
            shipment, providing IATA compliant crating and packaging services
            for different weights and dimension of cargo. In parallel, our
            one-stop documentation facilitates seamless export
          </div>
          <div>
            FLIGHT Leveraging on international networks and favourable
            partnerships, Noble will deliver your products anywhere in the world
            on-time and with utmost care.
          </div>
          <div>
            Fully-streamlined processes of document collection, customs and all
            statutory agency clearance, your cargo is guaranteed safe landing
          </div>
        </div>
      </div>
    </AirFreightCon>
  );
}

export default AirFreight;

const AirFreightCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    margin-left: -300px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
