import React from "react";
import styled from "styled-components";
import img1 from "../images/Warehousevs.webp";

import bkgroundimg from "../images/warehouse.avif";
function Warehouse() {
  return (
    <WarehouseCon>
      <div className="bkgpic_msg">
        <div className="message">Warehousing and Storage Services</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | WareHousing
      <div className="imgandesc">
        <div className="desc-container">
          <h2>Warehousing and Storage Services in Ghana</h2>
          <p>
            One of the key points of a supply chain is warehousing – choosing
            the right location and infrastructure for your business needs
            improves operations as well as lead times. In order to meet seasonal
            demand, our facilities are multi-user which allows great
            flexibility. There is also the possibility of choosing customized
            and unique warehouses specifically designed for your business using
            the appropriate equipment and technologies.
          </p>
          <br />
          <p>
            Delivery expert. No matter what type of services you need, Integral
            Freight has a solution for you, offering a full range of services to
            meet all your needs throughout Ghana: Storage and delivery: pallets,
            crating and packing Printing and labeling Sorting and packing
            <br />
            <h3>6 reasons why you should store your goods.</h3>
            <h4>Seasonal Inventory Management:</h4> Businesses that experience
            seasonal fluctuations in demand can benefit from storage. They can
            stock up on inventory during slow seasons and store the surplus
            until demand increases. Cost Savings: Warehousing your goods can
            lead to cost savings. Bulk purchasing and storing items in larger
            quantities can often result in discounts from suppliers.
            Additionally, storing goods in a cost-effective manner can reduce
            the overall operational costs.
          </p>
          <StyledTip1>
            NOBLE Tip: Flexibility, reactiveness, and constant follow-up: that
            is what makes us special !
          </StyledTip1>
          Supply Chain Optimization: Storing goods strategically in warehouses
          can optimize the supply chain. This allows for better management of
          transportation and distribution logistics, ensuring timely delivery to
          customers or retailers. Flexibility and Scaling: Storage facilities
          provide flexibility for businesses to scale their operations. Whether
          a business is growing or downsizing, having access to additional
          storage space allows for better adaptation to changing circumstances.
          <StyledTip2>
            NOBLE Tip: Flexibility, reactiveness, and constant follow-up: that
            is what makes us special !
          </StyledTip2>
          Inventory Management: Efficient inventory management is crucial for
          businesses. Storage facilitates organized and systematic inventory
          control. It allows for better tracking of stock levels, reducing the
          likelihood of stockouts or overstock situations. E-commerce and Retail
          Support: For e-commerce businesses or retailers, having a storage
          solution is essential. It enables them to maintain a diverse product
          range, manage fluctuations in demand, and fulfill customer orders
          promptly.
        </div>

        <div className="image-container">
          <img className="image" src={img1} alt=" " />

          <h4>Key Services</h4>
          <ul>
            <li>
              <a href="/ProjectCargoCon">Project Logistics</a>
            </li>
            <li>
              <a href="/DoortoDoorCon">Door to Door</a>
            </li>
            <li>
              <a href="/CustomsBrokageCon">Customs Brokage</a>
            </li>
            <li>
              <a href="/PackPercelCon">Packages and Percel</a>
            </li>
          </ul>
        </div>
      </div>
    </WarehouseCon>
  );
}

export default Warehouse;

const WarehouseCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    color: white;
    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
const StyledTip1 = styled.div`
  background: gold;
  border: 3px solid gainsboro;
  border-radius: 0px 30px 0px 30px;
  padding: 30px;
  font-size: 20px;
  opacity: 0.9;
`;

const StyledTip2 = styled(StyledTip1)`
  background: lightskyblue;
`;
