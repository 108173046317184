import React, { useEffect, useState } from "react";
import slideimg1 from "../images/ocean-freight.jpg";
import slideimg2 from "../images/ffworkers.png";
import slideimg3 from "../images/warehouse.avif";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import styled from "styled-components";

function HeroSlider() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slideLength = herosliderData.length;
  const autoScroll = true;
  const intervalTime = 7000;
  let slideInterval;

  useEffect(() => {
    setCurrentSlide(0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
  };

  const prevSlide = () => {
    setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
  };

  function auto() {
    slideInterval = setInterval(nextSlide, intervalTime);
  }

  useEffect(
    () => {
      if (autoScroll) {
        auto();
      }
      return () => clearInterval(slideInterval);
    } /* [currentSlide] */
  );

  return (
    <HeroSliderCon>
      <div className="slider">
        <FaRegArrowAltCircleLeft className="arrow prev" onClick={prevSlide} />
        <FaRegArrowAltCircleRight className="arrow next" onClick={nextSlide} />
        {herosliderData.map((slide, index) => (
          <div
            className={index === currentSlide ? "slide current" : "slide"}
            key={index}
          >
            {index === currentSlide && (
              <>
                <img className="slideimg" src={slide.image} alt="slide" />

                <div className="content">
                  <h3>{slide.heading}</h3>
                  <p>{slide.desc}</p>

                  <hr />
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </HeroSliderCon>
  );
}

export default HeroSlider;

const HeroSliderCon = styled.div`
  width: 100%;
  height: 70vh;
  background: rgba(0, 0, 0, 0.6);

  .slider {
    width: 100%;
    height: 70vh;
    position: relative;
    overflow: hidden;
    margin-top: 0;
  }

  .slideimg {
    width: 100%;
    height: 70vh;
  }
  .slide {
    width: 100%;
    height: 70vh;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
  }
  .content {
    position: absolute;
    top: 10rem;
    left: 4.5rem;
    opacity: 0;
    width: 35%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.6);
    animation: slide-up 1s ease-in-out 0.5s forwards;
  }
  .content > * {
    color: #fff;
    margin-bottom: 0.2rem;
  }
  @keyframes slide-up {
    0% {
      visibility: visible;
      top: 0rem;
    }
    100% {
      visibility: visible;
      top: 10rem;
    }
  }
  .current {
    opacity: 1;
    transform: translateX(0);
  }

  .current .content {
    opacity: 1;
  }

  .arrow {
    border: 2px solid #fff;
    border-radius: 50%;
    background: #333;
    color: #fff;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: absolute;
    top: 25%;
    z-index: 999;
  }

  .arrow:hover {
    background: hsl(229, 54%, 51%);
  }

  .next {
    right: 2rem;
  }

  .prev {
    left: 2rem;
  }

  hr {
    width: 55%;
  }

  /***************************/
  @media (max-width: 700px) {
  width: 100%;
    height: 30vh;
  .slideimg {
    width: 100%;
    height: 30vh;
  }

    .content {
    position: absolute;
    top: 1rem;
    left: 3.5rem;
    opacity: 0;
    width: 50%;
    padding: 1rem;
    font-size: 14.5px;
    background: rgba(0, 0, 0, 0.6);
    animation: slide-up 1s ease-in-out 0.5s forwards;
  }
  
    }
  }
  /**************************/
`;

export const herosliderData = [
  {
    image: slideimg1,
    heading: "We will deliver on our promises",
    desc: "Don’t Move, We’ll Handle Everything",
  },
  {
    image: slideimg2,
    heading: "Professional staff",
    desc: "We have the professional staff who are ready to serve you",
  },
  {
    image: slideimg3,
    heading: "Great warehouse",
    desc: "Great warehouse provider",
  },
];
