import React from "react";
import styled from "styled-components";

import bkgroundimg from "../images/van.jpg";
import { TbClockHour6Filled } from "react-icons/tb";
function Howtoapply() {
  return (
    <HowtoapplyCon>
      <div className="bkgpic_msg">
        <div className="message"></div>
      </div>
      <a className="globalhome" href="/">
        Global Home
      </a>
      | JOBS
      <div className="imgandesc">
        <div className="w_2">
          All applications should be sent to nobleshipingagency@yahoo.com
          Recruitment Scam Notice NOBLE warns the public to be cautious of any
          recruitment scam activities. We have been made aware of unauthorized
          individuals using the Ghana National Petroleum Corporation name and
          logo in emails and websites in attempt to solicit up-front fees (eg.
          Application, processing fee) from interested job-seekers. NOBLE wishes
          to stress that it has not authorised any such email based recruitment
          or otherwise. We appeal to the public not to responds to such notices.
          <br />
          <br />
          There are a few quick ways to recognise fraud:
          <br />
          <br />
          <ul>
            <li>
              {" "}
              NOBLE official website pages always include
              https://www.nobleshippinggh.com. Any other format is not genuine.{" "}
            </li>
            <br />
            <li>
              Email communication on recruitment from NOBLE, if any, would be
              sent by NOBLE officials using only e-mail domains of
              @nobleshippinggh.com.
            </li>
            <br />
            <li>
              No money transfers or payments of any kind will EVER be requested
              from applicants nor by NOBLE at any point in the recruitment
              process.
            </li>
            <br />
          </ul>
          Should you experience any of the foregoing, please treat the
          communication as fraudulent and forward it to
          nobleshipingagency@yahoo.com
        </div>
        <div className="desc-container w_3">
          <div>
            <h6>Posted February 6, December 2024 at 12:56 pm</h6>
            Marketer
            <hr style={{ width: "12%" }} />
          </div>
          <div>
            <p>
              <h4>Key Responsibilities</h4>
              <br />
              1. Market Research and Analysis Conduct market research to
              identify customer needs, preferences, and trends. ◦ Analyze
              competitor activities and market conditions to inform marketing
              strategies. ◦ Use data analytics tools to measure the
              effectiveness of marketing campaigns and adjust strategies
              accordingly.
              <br />
              <br />
              2. Strategic Planning ◦ Develop and implement comprehensive
              marketing plans and strategies that align with business
              objectives. ◦ Identify target audiences and create customer
              personas to tailor marketing efforts. ◦ Set specific marketing
              goals and KPIs to track performance and ROI.
              <br />
              <br />
              3. Content Creation and Management ◦ Create engaging content for
              various marketing channels, including websites, blogs, social
              media, and email campaigns. ◦ Oversee the production of marketing
              materials such as brochures, videos, and advertisements. ◦ Ensure
              consistency in brand messaging and visual identity across all
              platforms.
              <br />
              <br />
              4. Digital Marketing ◦ Manage digital marketing campaigns,
              including SEO, SEM, PPC, and social media advertising. ◦ Optimize
              website content and landing pages for search engines to improve
              organic traffic. ◦ Monitor and analyze web traffic and user
              behavior using analytics tools like Google Analytics. 5. Social
              Media Management ◦ Develop and execute social media strategies to
              increase brand awareness and engagement. ◦ Create and curate
              content for social media platforms, including Facebook, Twitter,
              LinkedIn, Instagram, and others. ◦ Monitor social media channels
              for customer feedback and respond appropriately.
              <br />
              <br />
              6. Campaign Management ◦ Plan and execute marketing campaigns,
              including product launches, promotions, and events. ◦ Coordinate
              with internal teams and external vendors to ensure timely and
              effective campaign execution. ◦ Track campaign performance and
              make data-driven adjustments to improve results.
              <br />
              <br />
              7. Customer Relationship Management (CRM) ◦ Develop and maintain
              customer databases to support targeted marketing efforts. ◦
              Implement CRM strategies to enhance customer loyalty and
              retention. ◦ Analyze customer data to identify trends and
              opportunities for personalized marketing.
            </p>
            <br />
            <h4>Skills and Qualifications Education:</h4>
            <br />
            Bachelor’s degree in Marketing, Business Administration, or a
            related field. Experience: Proven experience in a marketing role,
            with a strong understanding of digital marketing tools and
            techniques. Skills: Excellent written and verbal communication
            skills. Strong analytical and problem-solving abilities. Proficiency
            in marketing software and tools (e.g., Google Analytics, SEO tools,
            CRM systems).
          </div>
        </div>
      </div>
    </HowtoapplyCon>
  );
}

export default Howtoapply;

const HowtoapplyCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }

  .desc-container {
    width: 75vw;
    margin-left: calc((100vw - 75vw) / 2);
    margin-right: calc((100vw - 75vw) / 2);
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 2px;
  }
  .imgandesc .desc-container {
    padding: 20px;
    /*     background: gainsboro; */

    text-align: justify;
  }

  .imgandesc .w_2 {
    grid-column: span 2;
    width: 75vw;
    margin-left: calc((100vw - 75vw) / 2);
    margin-right: calc((100vw - 75vw) / 2);
    text-align: left;
    padding-left: 30px;
  }

  .desc-container div {
    background-color: rgba(2, 17, 27, 0.07);
    padding: 10px;
    border-radius: 5px;
  }

  .desc-container .w_3 {
    grid-column: span 3;
  }
  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
