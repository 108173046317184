import React from "react";
import styled from "styled-components";
import img1 from "../images/hcare1.jpg";
import img2 from "../images/hcare3.jpg";
import noblepro from "../images/hcare11.jpg";
import img3 from "../images/project_cargo1.jpg";

import bkgroundimg from "../images/hcare.jpg";
function HealthCare() {
  return (
    <HealthCareCon>
      <div className="bkgpic_msg">
        <div className="message">
          Take advantage of customized healthcare supply chain soloutions
        </div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | HealthCare
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          <h2>
            Fortify your organization’s reputation with trusted healthcare
            supply chain solutions customized to your needs
          </h2>
          Our staff of dedicated healthcare logistics professionals have decades
          of experience developing secure, adaptable healthcare supply chain
          solutions — tailored to each client’s unique goals.
        </div>

        <div className="desc-container">
          <h3>
            Enhance your shipping with trusted, proven healthcare supply chain
            solutions
          </h3>
          When it comes to sensitive, critical healthcare deliveries, NOBLE
          provides cost-effective, secure B2B and B2C shipments that adhere to
          strict protocols.
          <br />
          <br />
          <p>
            Our logistics services ensure that packages are handled with the
            highest standards of security and compliance. We are proud to hold
            several key certifications that guarantee the safety and efficiency
            of our operations
          </p>
        </div>
        <div className="image-container">
          <img className="image" src={img2} alt=" " />
        </div>
      </div>
      <div className="noblepro">
        <div className="messagepro">
          <h1>Partner with leading healthcare logistics professionals</h1>
          <p>
            If you know what services you're looking for, start a quote today.
            Or get in touch with our logistics consultants for additional
            information regarding NOBLE’s solutions and capabilities.
          </p>
          <br />
          <button>
            <a href="/GetaQuoteCon">SHIP WITH NOBLE</a>
          </button>
        </div>
      </div>
    </HealthCareCon>
  );
}

export default HealthCare;

const HealthCareCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;

    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  .noblepro {
    background: url(${noblepro});

    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 65vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }
  .messagepro {
    background: gainsboro;
    border-radius: 0px 100px 100px 0px;
    position: relative;
    width: 50%;
    font-weight: bold;
    padding: 10px;
    text-align: left;
  }

  .messagepro:hover {
    background: #fffdd0;
  }

  button {
    position: relative;
    bottom: 0px;
    background: white;

    cursor: pointer;
    padding: 12px;
    border-radius: 15px;
  }
  button:hover {
    background: #023e7d;
    color: white;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
