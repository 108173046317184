import React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
/* import CookieConsent from "react-cookie-consent"; */
import Header from "./Components/Header";
import HeroSlider from "./Components/HeroSlider";
import Home from "./Components/Home";
import ProjectCargo from "./Components/ProjectCargo";
import Footer from "./Components/Footer";
import DoortoDoor from "./Components/DoortoDoor";
import HsegoodsMov from "./Components/HsegoodsMov";
import PackPercel from "./Components/PackPercel";
import CustomsBrokage from "./Components/CustomsBrokage";
import OceanFreight from "./Components/OceanFreight";
import WareHouse from "./Components/Warehouse";
import Transborder from "./Components/Transborder";
import AboutUs from "./Components/AboutUs1";
import AirFreight from "./Components/AirFreight";
import MovGh from "./Components/MovGh";
import CorpReloc from "./Components/CorpReloc";
import PurchaseOnline from "./Components/PurchaseOnline";
import GetaQuote from "./Components/GetaQuote.js";
import Incoterms from "./Components/Incoterms.js";
import DangrousGoods from "./Components/DangrousGoods.js";
import Food from "./Components/Food.js";
import ProduceShipping from "./Components/ProduceShipping.js";
import MovingTips from "./Components/MovingTips.js";
import WhyChooseUs from "./Components/WhyChooseUs";
import HealthCare from "./Components/HealthCare.js";
import Vacancies from "./Components/Vacancies.js";
import Feedback from "./Components/FeedBack.js";
import Howtoapply from "./Components/Howtoapply.js";
import FreightForwarding from "./Components/FreightForwarding.js";
import Faq from "./Components/Faq.js";
import Disclaimer from "./Components/Disclaimer.js";
import TermsAndCond from "./Components/TermsAndCond.js";
import Login from "./Components/Login.js";
/*
import SpecialLight from "./Components/SpecialLight";
import Services from "./Components/Services";
import ViMi from "./Components/ViMi";

import FreightForwarding from "./Components/FreightForwarding.js";

import Privacy from "./Components/Privacy.js";
import ContactUs from "./Components/ContactUs.js";
import Sales from "./Components/Sales";

*/

function App() {
  return (
    <>
      <Feedback />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <Header />
              <HeroSlider />
              <Home />
              <Footer />
            </>
          }
        />

        <Route
          path="/FreightForwardingCon"
          element={
            <>
              <Header />
              <FreightForwarding />
              <Footer />
            </>
          }
        />
        <Route
          path="/ProjectCargoCon"
          element={
            <>
              <Header />
              <ProjectCargo />
              <Footer />
            </>
          }
        />
        <Route
          path="/HowtoapplyCon"
          element={
            <>
              <Header />
              <Howtoapply />
              <Footer />
            </>
          }
        />
        <Route
          path="/HealthCareCon"
          element={
            <>
              <Header />
              <HealthCare />
              <Footer />
            </>
          }
        />
        <Route
          path="/HsegoodsMovCon"
          element={
            <>
              <Header />
              <HsegoodsMov />
              <Footer />
            </>
          }
        />

        <Route
          path="/LoginCon"
          element={
            <>
              <Login />
            </>
          }
        />
        <Route
          path="/MovingTipsCon"
          element={
            <>
              <Header />
              <MovingTips />
              <Footer />
            </>
          }
        />
        <Route
          path="/ProduceShippingCon"
          element={
            <>
              <Header />
              <ProduceShipping />
              <Footer />
            </>
          }
        />
        <Route
          path="/FoodCon"
          element={
            <>
              <Header />
              <Food />
              <Footer />
            </>
          }
        />
        <Route
          path="/VacanciesCon"
          element={
            <>
              <Header />
              <Vacancies />
              <Footer />
            </>
          }
        />
        <Route
          path="/GetaQuoteCon"
          element={
            <>
              <Header />
              <GetaQuote />
              <Footer />
            </>
          }
        />

        <Route
          path="/FaqCon"
          element={
            <>
              <Faq />
            </>
          }
        />
        <Route
          path="/DisclaimerCon"
          element={
            <>
              <Disclaimer /> <Footer />
            </>
          }
        />
        <Route
          path="/PurchaseOnlineCon"
          element={
            <>
              <Header />
              <PurchaseOnline />
              <Footer />
            </>
          }
        />
        <Route
          path="/CustomsBrokageCon"
          element={
            <>
              <Header />
              <CustomsBrokage />
              <Footer />
            </>
          }
        />
        <Route
          path="/TransborderCon"
          element={
            <>
              <Header />
              <Transborder />
              <Footer />
            </>
          }
        />
        <Route
          path="/IncotermsCon"
          element={
            <>
              <Header />
              <Incoterms />
              <Footer />
            </>
          }
        />
        <Route
          path="/PackPercelCon"
          element={
            <>
              <Header />
              <PackPercel />
              <Footer />
            </>
          }
        />
        <Route
          path="/DangrousGoodsCon"
          element={
            <>
              <Header />
              <DangrousGoods />
              <Footer />
            </>
          }
        />
        <Route
          path="/OceanFreightCon"
          element={
            <>
              <Header />
              <OceanFreight />
              <Footer />
            </>
          }
        />
        <Route
          path="/DoortoDoorCon"
          element={
            <>
              <Header />
              <DoortoDoor />
              <Footer />
            </>
          }
        />
        <Route
          path="/AirFreightCon"
          element={
            <>
              <Header />
              <AirFreight />
              <Footer />
            </>
          }
        />
        <Route
          path="/AboutUsCon"
          element={
            <>
              <Header />
              <AboutUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/CorpRelocCon"
          element={
            <>
              <Header />
              <CorpReloc />
              <Footer />
            </>
          }
        />
        <Route
          path="/TermsAndCondCon"
          element={
            <>
              <Header />
              <TermsAndCond />
              <Footer />
            </>
          }
        />
        <Route
          path="/MovGhCon"
          element={
            <>
              <Header />
              <MovGh />
              <Footer />
            </>
          }
        />
        <Route
          path="/WhyChooseUsCon"
          element={
            <>
              <Header />
              <WhyChooseUs />
              <Footer />
            </>
          }
        />
        <Route
          path="/WareHouseCon"
          element={
            <>
              <Header />
              <WareHouse />
              <Footer />
            </>
          }
        />
      </Routes>
    </>
  );
}

export default App;
