import React from "react";
import styled from "styled-components";
import img1 from "../images//movtip.jpg";

import bkgroundimg from "../images/moveghpicmap.jpg";
function MovingTips() {
  return (
    <MovingTipsCon>
      <div className="bkgpic_msg">
        <div className="message">Moving To Ghana</div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Moving To Ghana
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          <h3>Moving Guide And Tips</h3> Planning your move: Before the move
          <p>The Initial Consultation</p>
          <ul>
            <li>
              Noble Shipping Agency representative will provide an initial
              telephone consultation and we will gladly give you advise and
              guidance, as well as an estimate of moving costs and ways in which
              you can minimize those costs. A trained representative will come
              to your home at your convenience for a free estimate. This is all
              without any obligation whatsoever on your part.
            </li>
            <p>Preliminary Packing</p>
            <li>
              Prior to moving day, you will be involved in preliminary packing (
              the consolidation and packing of your belongings mostly
              unbreakable such as linens, books, pots, toys, etc.) into cartons
              which Noble Shipping Agency will supply.
            </li>
            <li>
              On moving day our professional team of packers will arrive at your
              home with specially designed cartons, padded materials and, if
              necessary, custom-built boxes to safety pack your furniture, the
              remainder of goods, including breakables.
            </li>
            <p>Secondary Packing</p>
            <li>
              Noble Shipping Agency will build heavy-duty "lift-vans" which are
              custom-made boxes designed to the size specifications of your
              shipment. These super-strong boxes resist all handling incurred in
              route to your new destination. All of your goods are packed into
              wooden, steel-strapped lifts, where they are safely kept until at
              your destination.
            </li>
            <p>Arrival at the Destination</p>
            <p>Off the Ship</p>
            <li>
              Following the departure from your local port of exit, our services
              continue in the country of your destination. With over 600 agents
              and affiliates worldwide, we are able to coordinate the move and
              pave the way for smooth customs clearance and expeditious service.
              Once the shipment is cleared, our agent will make arrangements for
              the delivery of your goods to your new home, office or venue.
            </li>
            <p>
              Clearing Your Baggage Depending upon the country you are moving
              to, you will need some or all of the following documents in order
              to clear customs:
            </p>
            <ul>
              <li>Passport</li>
              <li>Immigrant Certificate</li>
              <li>Detailed packing list with value of contents</li> Receipts for
              appliances and new furniture Tenancy contract or title for new
              house Proper proof of tax exemption, it entitled
              <li>Copy of agreement with Noble Shipping Services</li>
              <li>Delivery order notice</li>
            </ul>
          </ul>
          <p>
            Ways to Save One if the best ways to save on moving is selective
            packing. Will that king-sized bedroom set fit your new home aboard?
            Will that appliance work on the electrical current in your new home?
            Will you be able to get spare parts for it?
          </p>
          <p>
            These are questions you should ask, and your Noble Shipping Agency
            representative will help you answer them. He knows which appliances
            will work and whether they would be cheaper to replace here or
            overseas. He can even arrange for the appliances you buy here to be
            delivered directly to the Noble Shipping Agency warehouse for
            shipping. Noble Shipping Agency will take the best possible care of
            your belongings, using sophisticated security measures at our
            warehouses.
          </p>
          <p>Insurance</p>
          <p>
            There are times when for reasons beyond our control (i.e. acts of
            God, fire, hijacking, etc.) when your goods may be damaged or lost.
            For this reason we suggest you always insure your goods with an "
            All Risk, Door to Door Marine insurance" policy which should take
            effect on the date which your goods arrive at our warehouse
            facilities. (Note: The All Risk Marine Insurance includes war
            insurance, which covers hijackings.) While the cost of insurance is
            not covered by our estimate, your Noble Shipping Agency
            representative will be glad to guide you in the matter.
          </p>
          <p>
            Remember, always insure your goods for the amount of money it would
            cost to replace then in the country you are moving to. Costs
            overseas are often mush higher than in the United States.
          </p>
        </div>
      </div>
    </MovingTipsCon>
  );
}

export default MovingTips;

const MovingTipsCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;

    font-size: 50px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
