import React from "react";
import styled from "styled-components";
import onlineshop1 from "../images/Alibaba-Logo-3.png";
import onlineshop2 from "../images/amazon.png";
import onlineshop3 from "../images/aliexpress.avif";
import onlineshop4 from "../images/ebay.jpg";
import onlineshop5 from "../images/walmart.png";
import onlineshop6 from "../images/etsy.webp";

import bkgroundimg from "../images/card.avif";
function PurchaseOnline() {
  return (
    <PurchaseOnlineCon>
      <div className="bkgpic_msg">
        <div className="message">
          <h2>International Online Shopping From USA to Ghana</h2>
          <br />
          <p>
            Have you found something you need online but the store won’t ship to
            Africa? We understand your frustrations and we can help. Shop from
            your favourite online stores and we’ll deliver to your door.
          </p>
        </div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Online Purchase
      <div className="imgandesc">
        <div className="desc-container">
          <ul className="purchasesteps">
            <li>
              Shop
              <ul>
                <li>Start shopping on your desired platforms</li>
              </ul>
            </li>

            <li>
              Ship to us
              <ul>
                <li>
                  At checkout from the online stores, choose to ship the items
                  to your unique Noble Address
                </li>
              </ul>
            </li>

            <li>
              Pay
              <ul>
                <li>
                  Tell us what you bought and pay for international shipping
                </li>
              </ul>
            </li>
            <li>
              Delivered to you
              <ul>
                <li>
                  We ship the items, take it through customs clearance and
                  deliver to your door
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <center>
        <h1>Start shopping Now </h1>
        <p>
          You can choose to start with any of the suggested shopping links
          below.
        </p>
        <br />
        <div className="FoodSolutions">
          {FoodData.map((FoodSolu) => (
            <div className="gallery-item" key={FoodSolu.id}>
              <div className="comimages">
                <a
                  href={FoodSolu.urlink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="cimg" src={FoodSolu.comimage} alt="logo" />
                </a>
              </div>
            </div>
          ))}
        </div>
      </center>
      <button>View More</button>
      <br />
      <br />
    </PurchaseOnlineCon>
  );
}

export default PurchaseOnline;

export const FoodData = [
  {
    comimage: onlineshop6,
    urlink: "https://www.etsy.com/",
  },

  {
    comimage: onlineshop2,
    urlink: "https://www.amazon.com/",
  },
  {
    comimage: onlineshop3,
    urlink: "https://www.aliexpress.com/",
  },

  {
    comimage: onlineshop4,
    urlink: "https://www.ebay.com/",
  },
  {
    comimage: onlineshop5,
    urlink: "https://www.walmart.com",
  },
  {
    comimage: onlineshop1,
    urlink: "https://www.alibaba.com",
  },
];

const PurchaseOnlineCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    color: white;
    padding: 120px;
    text-align: justify;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  .imgandesc .desc-container {
    padding: 20px;
    grid-column: span 2;
    text-align: justify;
  }
  .purchasesteps {
    display: flex;
    justify-content: space-between;
    background-color: #003554;
    align-items: center;
    color: white;
    font-size: 12.5px;
    font-weight: bold;
    padding: 4px;
    height: 20vh;
    gap: 20px;
  }

  .purchasesteps li {
    list-style-type: none; /* Remove bullets */
  }
  .purchase {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    /*  grid-auto-rows: 100px 300px; */
    gap: 40px;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    justify-content: center;
  }

  .FoodSolutions {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 50px;
    gap: 15px;
  }

  .cimg {
    width: 200px;
    height: 100px;
  }
  .comimages {
    width: 200px;
    height: 100px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
