import React from "react";
import styled from "styled-components";
import img1 from "../images/dangclass1.jpg";
import img2 from "../images/dangclass2.jpg";
import img3 from "../images/dangclass3.jpg";
import dang4 from "../images/dangclass4.jpg";
import dang5 from "../images/dangclass5.jpg";
import dang6 from "../images/dangclass6.jpeg";
import dang7 from "../images/dangclass7.jpeg";
import dang8 from "../images/dangclass8.jpg";
import dang9 from "../images/dangclass9.jpg";
import bkgroundimg from "../images/aidangs.jpg";
function DangrousGoods() {
  return (
    <DangrousGoodsCon>
      <div className="bkgpic_msg">
        <div className="message"></div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Dangerous Goods
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          CLASS 1 – EXPLOSIVES Explosives are materials or items which have the
          ability to rapidly conflagrate or detonate as a consequence of
          chemical reaction. Sub-Divisions Division 1.1: Substances and articles
          which have a mass explosion hazard Division 1.2: Substances and
          articles which have a projection hazard but not a mass explosion
          hazard Division 1.3: Substances and articles which have a fire hazard
          and either a minor blast hazard or a minor projection hazard or both
          Division 1.4: Substances and articles which present no significant
          hazard; only a small hazard in the event of ignition or initiation
          during transport with any effects largely confined to the package
          Division 1.5: Very insensitive substances which have a mass explosion
          hazard Division 1.6: Extremely insensitive articles which do not have
          a mass explosion hazard Reason for Regulation Explosives are capable
          by chemical reaction of producing gases at temperatures, pressures and
          speeds as to cause catastrophic damage through force and/or of
          producing otherwise hazardous amounts of heat, light, sound, gas or
          smoke. Commonly Transported Explosives Ammunition/cartridges
          Fireworks/pyrotechnics Flares Blasting caps / detonators Fuse Primers
          Explosive charges (blasting, demolition etc) Detonating cord Air bag
          inflators Igniters Rockets TNT / TNT compositions RDX / RDX
          compositions PETN / PETN compositions DGI DGI are proficient in
          handling explosives, Class 1 Dangerous Goods. DGI have the ability to
          service all customer requests pertaining to the logistics of
          explosives; packing, packaging, compliance, freight forwarding and
          training. Return to top of page.
        </div>
        <div className="desc-container">
          CLASS 2 – GASES Gases are defined by dangerous goods regulations as
          substances which have a vapour pressure of 300 kPa or greater at 50°c
          or which are completely gaseous at 20°c at standard atmospheric
          pressure, and items containing these substances. The class encompasses
          compressed gases, liquefied gases, dissolved gases, refrigerated
          liquefied gases, mixtures of one or more gases with one or more
          vapours of substances of other classes, articles charged with a gas
          and aerosols. Sub-Divisions Division 2.1: Flammable gases Division
          2.2: Non-flammable, non-toxic gases Division 2.3: Toxic gases Reason
          for Regulation Gases are capable of posing serious hazards due to
          their flammability, potential as asphyxiants, ability to oxidize
          and/or their toxicity or corrosiveness to humans. Commonly Transported
          Gases Aerosols Compressed air Hydrocarbon gas-powered devices Fire
          extinguishers Gas cartridges Fertilizer ammoniating solution
          Insecticide gases Refrigerant gases Lighters Acetylene / Oxyacetylene
          Carbon dioxide Helium / helium compounds Hydrogen / hydrogen compounds
          Oxygen / oxygen compounds Nitrogen / nitrogen compounds Natural gas
          Oil gas Petroleum gases Butane Propane Ethane Methane Dimethyl ether
          Propene / propylene Ethylene DGI DGI are proficient in handling gases,
          Class 2 Dangerous Goods. DGI have the ability to service all customer
          requests pertaining to the logistics of gases; packing, packaging,
          compliance, freight forwarding and training.
        </div>
        <div className="image-container">
          <img className="image" src={img2} alt=" " />
        </div>
        <div className="image-container">
          <img className="image" src={img3} alt=" " />
        </div>
        <div className="desc-container">
          CLASS 3 – FLAMMABLE LIQUIDS Flammable liquids are defined by dangerous
          goods regulations as liquids, mixtures of liquids or liquids
          containing solids in solution or suspension which give off a flammable
          vapour (have a flash point) at temperatures of not more than 60-65°C,
          liquids offered for transport at temperatures at or above their flash
          point or substances transported at elevated temperatures in a liquid
          state and which give off a flammable vapour at a temperature at or
          below the maximum transport temperature. Sub-Divisions There are no
          subdivisions within Class 3, Flammable Liquids. Reason for Regulation
          Flammable liquids are capable of posing serious hazards due to their
          volatility, combustibility and potential in causing or propagating
          severe conflagrations. Commonly Transported Flammable Liquids Acetone
          / acetone oils Adhesives Paints / lacquers / varnishes Alcohols
          Perfumery products Gasoline / Petrol Diesel fuel Aviation fuel Liquid
          bio-fuels Coal tar / coal tar distillates Petroleum crude oil
          Petroleum distillates Gas oil Shale oil Heating oil Kerosene Resins
          Tars Turpentine Carbamate insecticides Organochlorine pesticides
          Organophosphorus pesticides Copper based pesticides Esters Ethers
          Ethanol Benzene Butanols Dichloropropenes Diethyl ether Isobutanols
          Isopropyls Methanol Octanes DGI DGI are proficient in handling
          flammable liquids, Class 3 Dangerous Goods. DGI have the ability to
          service all customer requests pertaining to the logistics of flammable
          liquids; packing, packaging, compliance, freight forwarding and
          training.
        </div>
        <div className="desc-container">
          CLASS 4 – FLAMMABLE SOLIDS; SUBSTANCES LIABLE TO SPONTANEOUS
          COMBUSTION; SUBSTANCES WHICH EMIT FLAMMABLE GASES WHEN IN CONTACT WITH
          WATER Flammable solids are materials which, under conditions
          encountered in transport, are readily combustible or may cause or
          contribute to fire through friction, self-reactive substances which
          are liable to undergo a strongly exothermic reaction or solid
          desensitized explosives. Also included are substances which are liable
          to spontaneous heating under normal transport conditions, or to
          heating up in contact with air, and are consequently liable to catch
          fire and substances which emit flammable gases or become spontaneously
          flammable when in contact with water. Sub-Divisions Division 4.1:
          Flammable solids Division 4.2: Substances liable to spontaneous
          combustion Division 4.3: Substances which, in contact with water, emit
          flammable gases Reason for Regulation Flammable solids are capable of
          posing serious hazards due to their volatility, combustibility and
          potential in causing or propagating severe conflagrations. Commonly
          Transported Flammable Solids; Spontaneous Combustibles; ‘Dangerous
          When Wet’ Materials Alkali metals Metal powders Aluminium phosphide
          Sodium batteries Sodium cells Firelighters Matches Calcium carbide
          Camphor Carbon Activated carbon Celluloid Cerium Copra Seed cake Oily
          cotton waste Desensitized explosives Oily fabrics Oily fibres
          Ferrocerium Iron oxide (spent Iron sponge/direct-reduced iron (spent)
          Metaldehyde Naphthalene Nitrocellulose Phosphorus Sulphur DGI DGI are
          proficient in handling flammable solids, Class 4 Dangerous Goods. DGI
          have the ability to service all customer requests pertaining to the
          logistics of flammable solids; packing, packaging, compliance, freight
          forwarding and training
        </div>
        <div className="image-container">
          <img className="image" src={dang4} alt=" " />
        </div>
        <div className="image-container">
          <img className="image" src={dang5} alt=" " />
        </div>
        <div className="desc-container">
          CLASS 5 – OXIDIZING SUBSTANCES; ORGANIC PEROXIDES Oxidizers are
          defined by dangerous goods regulations as substances which may cause
          or contribute to combustion, generally by yielding oxygen as a result
          of a redox chemical reaction. Organic peroxides are substances which
          may be considered derivatives of hydrogen peroxide where one or both
          hydrogen atoms of the chemical structure have been replaced by organic
          radicals. Sub-Divisions Division 5.1: Oxidizing substances Division
          5.1: Organic peroxides Reason for Regulation Oxidizers, although not
          necessarily combustible in themselves, can yield oxygen and in so
          doing cause or contribute to the combustion of other materials.
          Organic peroxides are thermally unstable and may exude heat whilst
          undergoing exothermic autocatalytic decomposition. Additionally,
          organic peroxides may be liable to explosive decomposition, burn
          rapidly, be sensitive to impact or friction, react dangerously with
          other substances or cause damage to eyes. Commonly Transported
          Oxidizers; Organic Peroxides Chemical oxygen generators Ammonium
          nitrate fertilizers Chlorates Nitrates Nitrites Perchlorates
          Permanganates Persulphates Aluminium nitrate Ammonium dichromate
          Ammonium nitrate Ammonium persulphate Calcium hypochlorite Calcium
          nitrate Calcium peroxide Hydrogen peroxide Magnesium peroxide Lead
          nitrate Lithium hypochlorite Potassium chlorate Potassium nitrate
          Potassium chlorate Potassium perchlorate Potassium permanganate Sodium
          nitrate Sodium persulphate DGI DGI are proficient in handling
          oxidising agents and organic peroxides, Class 5 Dangerous Goods. DGI
          have the ability to service all customer requests pertaining to the
          logistics of oxidising agents and organic peroxides; packing,
          packaging, compliance, freight forwarding and training.
        </div>
        <div className="desc-container">
          CLASS 6 – TOXIC SUBSTANCES; INFECTIOUS SUBSTANCES Toxic substances are
          those which are liable either to cause death or serious injury or to
          harm human health if swallowed, inhaled or by skin contact. Infectious
          substances are those which are known or can be reasonably expected to
          contain pathogens. Dangerous goods regulations define pathogens as
          microorganisms, such as bacteria, viruses, rickettsiae, parasites and
          fungi, or other agents which can cause disease in humans or animals.
          Sub-Divisions Division 6.1: Toxic substances Division 6.2: Infectious
          substances Reason for Regulation Toxic and infectious substances can
          pose significant risks to human and animal health upon contact.
          Commonly Transported Toxic Substances; Infectious Substances
          Medical/Biomedical waste Clinical waste Biological cultures / samples
          / specimens Medical cultures / samples / specimens Tear gas substances
          Motor fuel anti-knock mixture Dyes Carbamate pesticides Alkaloids
          Allyls Acids Arsenates Arsenites Cyanides Thiols/mercaptans Cresols
          Barium compounds Arsenics / arsenic compounds Beryllium/ beryllium
          compounds Lead compounds Mercury compounds Nicotine / nicotine
          compounds Selenium compounds Antimony Ammonium metavanadate
          Adiponitrile Chloroform Dichloromethane Hexachlorophene Phenol
          Resorcinol DGI DGI are proficient in handling toxic and infectious
          substances, Class 6 Dangerous Goods. DGI have the ability to service
          all customer requests pertaining to the logistics of oxidising agents
          and organic peroxides; packing, packaging, compliance, freight
          forwarding and training.Return to top of page.
        </div>
        <div className="image-container">
          <img className="danimg" src={dang6} alt=" " />
        </div>
        <div className="image-container">
          <img className="danimg" src={dang7} alt=" " />
        </div>
        <div className="desc-container">
          CLASS 7 – RADIOACTIVE MATERIAL Dangerous goods regulations define
          radioactive material as any material containing radionuclides where
          both the activity concentration and the total activity exceeds certain
          pre-defined values. A radionuclide is an atom with an unstable nucleus
          and which consequently is subject to radioactive decay. Sub-Divisions
          There are no subdivisions within Class 7, Radioactive Material. Reason
          for Regulation Whilst undergoing radioactive decay radionuclides emit
          ionizing radiation, which presents potentially severe risks to human
          health. Commonly Transported Radioactive Material Radioactive ores
          Medical isotopes Yellowcake Density gauges Mixed fission products
          Surface contaminated objects Caesium radionuclides / isotopes Iridium
          radionuclides / isotopes Americium radionuclides / isotopes Plutonium
          radionuclides / isotopes Radium radionuclides / isotopes Thorium
          radionuclides / isotopes Uranium radionuclides / isotopes Depleted
          uranium / depleted uranium products Uranium hexafluoride Enriched
          Uranium DGI DGI are proficient in handling radioactive material, Class
          7 Dangerous Goods. DGI have the ability to service all customer
          requests pertaining to the logistics of radioactive material; packing,
          packaging, compliance, freight forwarding and training.Return to top
          of page.
        </div>
        <div className="desc-container">
          CLASS 8 – CORROSIVES Corrosives are substances which by chemical
          action degrade or disintegrate other materials upon contact.
          Sub-Divisions There are no subdivisions within Class 8, Corrosives.
          Reason for Regulation Corrosives cause severe damage when in contact
          with living tissue or, in the case of leakage, damage or destroy
          surrounding materials. Commonly Transported Corrosives Acids/acid
          solutions Batteries Battery fluid Fuel cell cartridges Dyes Fire
          extinguisher charges Formaldehyde Flux Paints Alkylphenols Amines
          Polyamines Sulphides Polysulphides Chlorides Chlorosilanes Bromine
          Cyclohexylamine Phenol / carbolic acid Hydrofluoric acid Hydrochloric
          acid Sulfuric acid Nitric acid Sludge acid Hydrogen fluoride Iodine
          Morpholine DGI DGI are proficient in handling corrosives, Class 8
          Dangerous Goods. DGI have the ability to service all customer requests
          pertaining to the logistics of corrosives; packing, packaging,
          compliance, freight forwarding and training.Return to top of page.
        </div>
        <div className="image-container">
          <img className="danimg" src={dang8} alt=" " />
        </div>
        <div className="image-container">
          <img className="danimg" src={dang9} alt=" " />
        </div>
        <div className="desc-container">
          Miscellaneous dangerous goods are substances and articles which during
          transport present a danger or hazard not covered by other classes.
          This class encompasses, but is not limited to, environmentally
          hazardous substances, substances that are transported at elevated
          temperatures, miscellaneous articles and substances, genetically
          modified organisms and micro-organisms and (depending on the method of
          transport) magnetized materials and aviation regulated substances.
          Sub-Divisions There are no subdivisions within Class 9, Miscellaneous
          Dangerous Goods. Reason for Regulation Miscellaneous dangerous goods
          present a wide array of potential hazards to human health and safety,
          infrastructure and/ or their means of transport. Commonly Transported
          Miscellaneous Dangerous Goods Dry ice / cardice / solid carbon dioxide
          Expandable polymeric beads / polystyrene beads Ammonium nitrate
          fertilizers Blue asbestos / crocidolite Lithium ion batteries Lithium
          metal batteries Battery powered equipment Battery powered vehicles
          Fuel cell engines Internal combustion engines Vehicles Magnetized
          material Dangerous goods in apparatus Dangerous goods in machinery
          Genetically modified organisms Genetically modified micro-organisms
          Chemical kits First aid kits Life saving appliances Air bag modules
          Seatbelt pretensioners Plastics moulding compound Castor bean plant
          products Polychlorinated biphenyls Polychlorinated terphenyls
          Dibromodifluoromethane Benzaldehyde DGI DGI are proficient in handling
          miscellaneous dangerous goods, Class 9 Dangerous Goods. DGI have the
          ability to service all customer requests pertaining to the logistics
          of miscellaneous dangerous goods; packing, packaging, compliance,
          freight forwarding and training.
        </div>
      </div>
    </DangrousGoodsCon>
  );
}

export default DangrousGoods;

const DangrousGoodsCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    margin-left: -300px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }

  .image-container {
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 100px;
  }

  .image-container .image {
    margin: auto 0;
    width: 15vw;
    height: 20vh;
    border-radius: 50%;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
