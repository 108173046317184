import React from "react";
import styled from "styled-components";
import img1 from "../images/incotermspic.jpg";

import bkgroundimg from "../images/incoterms_pic.jpg";
function Incoterms() {
  return (
    <IncotermsCon>
      <div className="bkgpic_msg">
        <div className="message"></div>
      </div>
      <a className="globalhome" href="/">
        Global Home{" "}
      </a>
      | Project Cargo
      <div className="imgandesc">
        <div className="image-container">
          <img className="image" src={img1} alt=" " />
        </div>
        <div className="desc-container">
          <h2>What are Incoterms®? </h2>
          <p>
            Incoterms ® or International Commercial Terms are the common
            language of international trade. Established by the International
            Chamber of Commerce (ICC), they are standard terms which are
            commonly incorporated into contracts for the trade of goods around
            the world.
          </p>
          <br />
          <p>
            They help buyers and sellers avoid costly misunderstandings by
            clarifying who bears the obligations, costs and risks involved in
            the delivery of goods from sellers to buyers.
          </p>
          <br />
          <p>
            Incoterms® provide clarity and predictability to businesses across
            the globe.
          </p>
          <p>
            The Incoterms® were last updated in 2019 and hence Incoterms® 2020
            are the most current version of the terms. However, some buyers and
            sellers still prefer to use Incoterms® 2010.
          </p>
          <p>
            In total, there are 11 sets of Incoterms®, of which, 7 are
            applicable to all modes of transport, and the other 4 cater to sea
            and inland waterway transport only.
          </p>
          <br />
          <h2>Incoterms® for all modes of transport</h2>
          <ul>
            <li> Ex Works (EXW) </li>
            <li>Free Carrier (FCA) </li>
            <li>Carriage Paid To (CPT)</li>
            <li>Carriage and Insurance Paid To (CIP)</li>
            <li>
              {" "}
              Delivered at Place (DAP) Delivered at Place Unloaded (DPU) Deliver
            </li>
            <li>Duty Paid (DDP)</li>
          </ul>
          <h2>Incoterms® for sea and inland waterway transport </h2>
          <ul>
            <li>FreeAlongside Ship (FAS) </li>
            <li>Free on Board (FOB)</li>
            <li>Cost and Freight (CFR) </li>
            <li>Cost, Insurance and Freight (CIF)</li>
          </ul>
          <p>
            It can be hard to keep track of all the Incoterms® and what they
            mean for you and your business.
          </p>
          <p>
            That’s why we’ve created a simple infographic, summarising the key
            Incoterms® you will encounter when dealing with ocean or air
            shipping, and the split of responsibilities between buyer and
            seller.
          </p>
          <p>
            Note: Please visit the ICC (International Chamber of Commerce)
            website for full overview and insights on the Incoterms®.
          </p>
          <h2>Quick tips on Incoterms®</h2>
          <br />
          <p>
            When considering Incoterms® understanding what buyers and sellers
            must each do under each set of terms is key. Allocation of risk
            between the parties will shift depending on which Incoterms® you
            choose. During transactions related to a new shipment, there are a
            few questions you should seek to clarify with your buyer/seller:
          </p>
          <ul>
            <br />
            <li>Cost allocation: Who will pay for what?</li>
            <li>
              Obligations: Who will take on which obligation in the shipping
              process?
            </li>
            <li>Risk: Who covers which cargo risk or pays for insurance?</li>
          </ul>
          <br />
          <p>
            Answers to the above will give you the basic information you need to
            decide which Incoterms® should be incorporated into the contract. If
            you are using an Incoterm® in your sale and purchase contract, this
            should be set and agreed upon before the cargo is sold or the
            transportation is booked. Always consult the ICC's information and
            website for latest updates on Incoterms® and the full obligations
            and risks you will need to comply with for each set of terms. We
            recommend seeking legal advice to determine which Incoterms® are
            right for you.
          </p>
        </div>
      </div>
    </IncotermsCon>
  );
}

export default Incoterms;

const IncotermsCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  .bkgpic_msg {
    background-image: url(${bkgroundimg});
    background-repeat: no-repeat;
    background-position: center; /* Center the background image */
    background-size: cover; /* Ensure the image covers the entire div */
    height: 30vh;
    display: flex; /* Use flexbox to center the text inside */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    position: relative; /* Allow positioning of text elements over the image */
  }

  .message {
    font-weight: bold;
    margin-left: -300px;
  }
  .globalhome {
    padding-bottom: 20px;
  }

  .imgandesc {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    margin-top: 50px;
    gap: 30px;
  }
  .image-container .image {
    width: 35vw;
    height: 55vh;
  }

  .imgandesc .desc-container {
    padding: 20px;

    text-align: justify;
  }

  @media (max-width: 700px) {
    width: 100vw;

    .imgandesc {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      justify-content: center;
      margin-top: 50px;
      gap: 30px;
      margin-left: -85px;
      padding-left: 5px;
      padding-right: 15px;
    }
    .image-container .image {
      width: 100vw;
      height: 30vh;
      justify-content: center;
      align-items: center;
      border-radius: 0px 200px 200px 0px;
    }
    .desc-containe {
      width: 100%;
    }
    .bkgpic_msg {
      margin-left: -65px;
    }
  }
`;
