import React from "react";
import styled from "styled-components";

import client1 from "../images/ernest.jpeg";
import client2 from "../images/bae.jpeg";
import client3 from "../images/forever.jpeg";
import client4 from "../images/tomgrovel.png";
import client5 from "../images/for_mob_pd.png";
import client6 from "../images/galit.png";

function ClientSlides() {
  return (
    <ClientSlidesCont>
      <div className="logos">
        <div className="logos_slides">
          <Clientf logoSrc={client1} />
          <Clientf logoSrc={client2} />
          <Clientf logoSrc={client3} />
          <Clientf logoSrc={client4} />
          <Clientf logoSrc={client5} />
          <Clientf logoSrc={client6} />
        </div>

        <div className="logos_slides">
          <Clientf logoSrc={client1} />
          <Clientf logoSrc={client2} />
          <Clientf logoSrc={client3} />
          <Clientf logoSrc={client4} />
          <Clientf logoSrc={client5} />
          <Clientf logoSrc={client6} />
        </div>
      </div>
    </ClientSlidesCont>
  );
}

const Clientf = (props) => {
  return (
    <ClientfCon>
      <div className="clientsclass">
        <img src={props.logoSrc} className="logname" alt="" />
      </div>
    </ClientfCon>
  );
};

export default ClientSlides;
const ClientSlidesCont = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);

  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  white-space: nowrap;

  .logos {
    width: 75vw;
    gap: 40px;
    overflow: hidden;
    padding: 15px 0px;
    background: #e5e5e5;

    display: flex;
    position: relative;
  }

  .logos:before,
  .logos:after {
    position: absolute;
    top: 0;
    left: 0;
    contents: "";
    width: 250px;
    height: 100%;
    z-index: 1001;
  }

  .logos:before {
    left: 0;
    background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
  }

  .logos:after {
    right: 0;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
  }

  .logos_slides {
    display: flex;
    margin: 20px 0;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    animation: 35s logoslideAm infinite linear;
  }

  @keyframes logoslideAm {
    from {
      transform: translateX(0);
    }

    to {
      transform: translateX(-100%);
    }
  }
  .logos:hover .logos_slides {
    animation-play-state: paused;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    padding: 0px 0px;

    .logos {
      width: 100%;
    }
  }
`;

const ClientfCon = styled.div`
  .logname {
    width: 120px;
    height: 75px;
  }

  img {
    border-radius: 25%;
  }
`;
