import React from "react";
import styled from "styled-components";
/* import Footer from "./Footer"; */
import { ServicesData } from "./ServicesData";
import StreeFree from "./StreeFree";
import ourpro from "../images/ourproces.png";
/* import ClientSlides from "./ClientSlides"; */
/* import Testimonals from "./Testimonals"; */

function Services() {
  return (
    <ServicesCon>
      <div className="yy">
        <center>
          Comprehensive Logistics Services Provider
          <h3>Don’t Move, We’ll Handle Everything</h3>
        </center>
        <h1>
          Logistics
          <span
            style={{
              backgroundColor: "orange",
              borderRadius: "50%",
              color: "black",
              height: "18px",
              width: "18px",
            }}
          >
            So
          </span>
          lutions
        </h1>
        <p>
          Explore our various services Save time and money with our tailored
          logistics solutions for individuals and businesses. Our end-to-end
          services, from freight transportation to inventory management,
          streamline your operations, so you can focus on your core business and
          family.
        </p>
      </div>

      <div className="allservices" id="allservices">
        {ServicesData.map((service) => (
          <div className="gallery-item" key={service.id}>
            <div className="image">
              <img
                className="picplusdetalis"
                src={service.img}
                alt={service.ServiceName}
              />
            </div>
            <div className="details">
              <h3>{service.ServiceName}</h3>
              <p>{service.desc}</p>
              <center>
                <button
                  onClick={() => (window.location.href = service.LearnMore)}
                >
                  Explore further
                </button>
              </center>
            </div>
          </div>
        ))}
      </div>
      <div className="Stressfree">
        <div className="sf1">
          <h1>Start to Finish:</h1>
          <h4>
            Stress-Free Transport Solutions From Planning to Delivery, We Handle
            Every Detail
          </h4>
          NOBLE Shipping offers a full range of logistics and transport services
          with a focus on efficiency and reliability at a competitive price and,
          of course, the assurance of a personal service.
          <hr />
          <ul className="stflist">
            <li>35+ Years of Experience</li>
            <li>Agents in 50+ Countries Worldwide Full</li>
            <li>
              Range of Logistics and Transportation Services 24/7 Availability
            </li>
          </ul>
        </div>
        <StreeFree />
        <div className="sf2"></div>
      </div>
      <div className="howstep">
        How It Works – Simple, Fast, and Stress-Free
        <div className="steps">
          <div>
            <div className="stepnum">1</div>
            <div className="stepdesc">Tell us exactly what you need</div>
            NOBLE Shipping provides a full range of logistics and transportation
            services with particular emphasis on efficiency and reliability at a
            competitive rate and, of course, the assurance of personal service.
            Our shipping experts will provide you with our most competitive
            rates as soon as they have received your request.
          </div>
          <div>
            <div className="stepnum">2</div>
            <div className="stepdesc">
              We'll plan the perfect Transport for you
            </div>
            After receiving your request for a shipping quote, we will get in
            touch with you no later than 24 hours to discuss our pricing for
            your cargo. We may need more information from you in order to
            provide you with an accurate estimate, depending on the details you
            supply and the complexity of the shipping quote requested.
          </div>
          <div>
            <div className="stepnum">3</div>
            <div className="stepdesc">Enjoy your Goods!</div>
            We take full charge and responsibility of your cargo, from your
            doorstep until it gets delivered to the intended destination
            anywhere in the world. Regardless of the size and whether you are
            shipping from your home, retail outlet, factory or warehouse, our
            Door-to-Door Delivery service ensures your cargo is delivered
            securely and in a timely manner.
          </div>
        </div>
        <div
          className="ourproc"
          style={{ gridColumn: "span 3", width: "75vw" }}
        >
          <img src={ourpro} style={{ width: "72vw" }} alt="" />
        </div>
      </div>

      {/*       <Testimonals />
      <h3>clients</h3>
      <ClientSlides /> */}
      {/*  <Footer /> */}
    </ServicesCon>
  );
}

export default Services;

const ServicesCon = styled.div`
  width: 75vw;
  margin-left: calc((100vw - 75vw) / 2);
  margin-right: calc((100vw - 75vw) / 2);
  margin-top: 80px;

  .picplusdetalis {
    width: 35vw;
    height: 40vh;
    border-radius: 0px 200px 200px 0px;
  }

  button {
    position: relative;
    bottom: 0px;
    width: 50%;
    background: #002642;
    color: white;
    cursor: pointer;
    padding: 12px;
    border-radius: 5px;
  }

  button:hover {
    background: #023e7d;
  }

  .Stressfree {
    width: 75vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    text-align: justify;
  }

  .stflist li {
    padding-left: 5px;
  }
  .Stressfree .sf2 {
    width: 34.5vw;
    padding-right: 30;
  }

  ul {
    list-style-type: circle;
    padding-left: 20px; /* Indent the entire list */
  }

  hr {
    width: 50%;
  }

  .Stressfree .sf1 {
    width: 34.5vw;
  }

  h1 {
    text-decoration: underline;
    opacity: 0.5;
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .steps {
    width: 75vw;
    display: grid;
    grid-template-columns: repeat(3, 450px);
    text-align: justify;
    gap: 20px;
    height: 45vh;
  }

  .howstep {
    color: white;
    background-color: #00509d;
    padding: 10px 20px;
  }
  .steps .stepdesc {
    font-size: 24px;
    font-weight: bold;
    margin: 2rem 0;
    text-align: left;
  }

  .steps .stepnum {
    font-size: 40px;
    border: 3px solid black;
    width: 80px;
    height: 80px;
    color: white;
    border-radius: 20% 50% 80% 90%;
    background-color: orangered;
    text-align: center;
  }
  .allservices {
    display: grid;
    grid-template-columns: repeat(5, 264px);
    /*  grid-auto-rows: 100px 300px; */
    gap: 30px;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .ourproc {
    margin-top: -80px;
  }
  /*****************************************************/
  @media (max-width: 758px) {
    .yy {
      width: 100%;
    }

    .yy p {
      width: 100vw;
      margin-left: -55px;
      padding-right: 2px;
    }
    h1 {
      text-decoration: underline;
      opacity: 0.5;
      margin-bottom: 10px;
      margin-top: 5px;
    }

    .allservices {
      grid-template-columns: repeat(2, 264px);
      padding-left: 35px;
      padding-right: 25px;
      gap: 15px;
    }

    .Stressfree {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      margin-left: -50px;
    }
    .Stressfree .sf1 {
      width: 100%;
      padding: 5px;
    }

    .howstep {
      width: 100vw;
      margin-left: -50px;
      /* margin-right: 0px; */
    }

    .ourproc {
      grid-column: span 3;
      width: 10vw;
    }

    .ourproc img {
      margin-top: 650px;
      width: 100vw;
    }

    .howstep .steps {
      width: 100vw;
      /*  display: grid; */
      grid-template-columns: repeat(1, 450px);
      /*     text-align: justify; */
    }

    .Stressfree .sf1 .steps {
      grid-template-columns: repeat(1, 450px);
      gap: 20px;
    }
  }

  /***************************************/
  .gallery-item {
    border: 2px solid gainsboro;
    border-radius: 5px;
    border-radius: 00px 100px 0px 00px;
  }

  .details {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    position: relative;
  }

  .bi {
    font-size: bold;
  }
  .gallery-item .image img {
    width: 261px;
    height: 190px;
    object-fit: cover;
    object-position: 50% 50%;
    cursor: pointer;
    transition: 0.5s ease-in-out;
  }

  /*   .gallery-item .text {
    opacity: 0;
  } */
  .gallery-item:hover {
    transform: scale(1.006255);
    box-shadow: 0.25px 0.25px 0.25px 0.25px gray;
    border-radius: 00px 100px 0px 00px;
  }
`;
